import React, { useState, useEffect, useRef, Fragment } from "react";
import { Auth } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Tooltip from '@material-ui/core/Tooltip';
import {  
  ButtonGroup,  
} from "react-bootstrap";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toAbsoluteUrl } from "../../../_metronic";
import { Link, useHistory, Redirect } from "react-router-dom";
// Filtro
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
// archivos externos
import {ModalDetallePedido} from "../Components/modalDetallePedido";
import {ModalDetallePedidoRemision} from "../Components/modalDetallePedidoRemision";
import PedidoPDF from "./ProPedidoPDF";
import CircularProgress from '@material-ui/core/CircularProgress';
// import { TableBody } from "@material-ui/core";
// import {GetTableRemision} from "../Components/tableRemision";
// import {GetTablePedido} from "../Components/tablePedido";

const useStyles = makeStyles(theme => ({
  progress: {
      margin: theme.spacing(2),         
      color: "#f14258"
  },
  paper: {
      marginTop: theme.spacing(3),
      width: '100%',
      overflowX: 'auto',
      marginBottom: theme.spacing(2),
  },
  container: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
  },
  filtro: {
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),      
  },
  table: {
      minWidth: 650,
      border:'5px solid #d41f36',
      borderRadius:'10px',
      padding:'0px'
  },  
  tableHead:{
      backgroundColor: '#d41f36',
      color: '#fff',
      textAlign: 'center',
      margin:'0px'
  },
  tableHeadText:{
    fontSize:'12px',
    fontWeight:'bold',    
  },
  tableRowBody:{
    '&:nth-of-type(odd)': {
        backgroundColor: '#f1f1f1',
    },
  },
  tableDatos:{
      borderBottom:'0px'
  },
  
}));

const formatterPeso = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 2
});

function obtenerFecha(){
  var getfecha = new Date();
  return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}

export default function Pedido () {
  let refValue = useRef();
  let refValue2 = useRef();  
  const classes = useStyles();  
  let history = useHistory();
  var fecha = new Date();
  var fechaInicioMes = fecha.getFullYear()+'-'+('0' + (fecha.getMonth()+1)).slice(-2)+'-01';
  var fechaActual = fecha.getFullYear()+'-'+('0' + (fecha.getMonth()+1)).slice(-2)+'-'+('0' + (fecha.getDate())).slice(-2);

  const [showdblPedido, setdblPedido] = useState();
  
  // Init: Modal
  const [showDetalle, setDetalle] = useState(false);
  const [showRemision, setRemision] = useState(false);
  const [showPDF, setPDF] =useState(false);
  
  // Cierre Modal
  function handleClose() {    
    setDetalle(!showDetalle);        
  };
  function handleClose2() {    
    setRemision(!showRemision);        
  };

  // init:Carga de Datos
  let [state, setState] = useState({
    items: [],
    isLoaded: false
  })  

  const [showFechaInit, setFechaInit] = useState(false);
  const [showFechaFin, setFechaFin] = useState(false);

  function customSetFiltrarPedido () {       
    setState({ isLoaded: false });
    setFechaInit(refValue.current.value);
    setFechaFin(refValue2.current.value);      
    consultaPedidos(refValue.current.value,refValue2.current.value)
  } 

  // Filtrar pedido
  async function consultaPedidos(fechaInicial, fechaFinal) {      
    var fechaPedidoInicial = "";
    var fechaPedidoFinal = "";
    var today = new Date();    
    if (fechaInicial === undefined || fechaInicial === ""){
      fechaPedidoInicial = today.getFullYear()+'-'+('0' + (today.getMonth()+1)).slice(-2)+'-01 00:00:00';      
    } else {
      fechaPedidoInicial = fechaInicial;
    }
    if (fechaFinal === undefined || fechaFinal === ""){
      fechaPedidoFinal = fechaActual +" 23:59:59";      
    } else {
      fechaPedidoFinal = fechaFinal+" 23:59:59";
    }

    let response = null    

    if (localStorage.getItem('usuario') != null){
        response = localStorage.getItem('usuario');                   
    }else{
        console.log("No hay usuario almacenado en el localstorage");
        history.push({
            pathname: '/logout',               
        }); 
    }

    let fechaAlta = obtenerFecha();
    
    // store Procedures => sp_getPagos
    var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/pedidos';    
    var data = {
      "strEmail": response,           
      "datFechaPedidoInicio": fechaPedidoInicial,
      "datFechaPedidoFin": fechaPedidoFinal,
      "datFechaAlta": fechaAlta      
    };

    console.log(data);
    
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(json => {      
    //   console.log(json[0]);      
      setState({
        isLoaded: true,
        items: json[0],
      });
    });
  }

  useEffect(() => {      
    verificarUser();  
    consultaPedidos();        
  },[]);

  async function verificarUser(){
    let response = null;
    try{
        response = await Auth.currentUserInfo();   
        if(JSON.stringify(response) == JSON.stringify({}) ){
            console.log('Usuario no existe');
            history.push({
                pathname: '/logout',               
            }); 
        }                                
    }catch(e){
        console.log('Error get user')
        history.push({
            pathname: '/logout',               
        });                             
    }
}


  var { isLoaded, items} = state
  
  var granTotal = 0;
  var totalRegistros = 0;  

  if (!isLoaded){
      return(      
          <div className={"row justify-content-center"}>
              <CircularProgress className={classes.progress} color="secondary" size={100}/>      
          </div>
      )
  } else {            
      return (                              
        <div>
            <Paper className={classes.filtro}>
                <Typography variant="h5" component="h3">          
                    <form className={classes.container} noValidate>
                        <div >
                            <Button variant="contained" size="large" style={{ backgroundColor: '#e6e5eb' }} onClick={() => customSetFiltrarPedido()}>
                                <SearchIcon style={{ fontSize: 28}}/>
                            </Button>              
                        </div>
                      
                        <TextField                
                            inputRef={refValue}
                            id="fechaPedidoInicio"
                            label="Fecha Pedido Inicial"
                            type="date"                
                            defaultValue={(showFechaInit ? showFechaInit: fechaInicioMes)}
                            className={classes.textField}
                            style = {{width: '42%'}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            inputRef={refValue2}
                            id="fechaPedidoFin"
                            label="Fecha Pedido Final"
                            type="date"
                            defaultValue={(showFechaFin ? showFechaFin: fechaActual)}
                            className={classes.textField}
                            style = {{width: '42%'}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>                                                
                </Typography>
            </Paper>
                                      
            <Paper className={classes.paper} style={{marginBottom:'5px'}}>
                <Table className={classes.table} size="small">                    
                    <TableHead className={classes.tableHead}>
                        <TableRow>                            
                            <TableCell align='center' className={classes.tableHeadText} style={{color:'#FFF'}}>Pedido</TableCell>
                            <TableCell align='center' className={classes.tableHeadText} style={{color:'#FFF'}}>Ord. de Compra</TableCell>
                            <TableCell align='center' className={classes.tableHeadText} style={{color:'#FFF'}}>Obra</TableCell>
                            <TableCell align='center' className={classes.tableHeadText} style={{color:'#FFF'}}>F. Pedido</TableCell>
                            {/* <TableCell align='center' className={classes.tableHeadText} style={{color:'#FFF'}}>F. Entrega</TableCell> */}
                            <TableCell align='center' className={classes.tableHeadText} style={{color:'#FFF'}}>Vendedor</TableCell>
                            <TableCell align='center' className={classes.tableHeadText} style={{color:'#FFF'}}>Estatus del Pedido</TableCell>
                            <TableCell align='right'  className={classes.tableHeadText}  style={{color:'#FFF'}}>Total</TableCell>
                            <TableCell align='center'  className={classes.tableHeadText}  style={{color:'#FFF'}}>Soporte</TableCell>
                            {/* <TableCell align='right'  className={classes.tableHeadText}  style={{color:'#FFF'}}>Remision</TableCell>
                            <TableCell align='right'  className={classes.tableHeadText}  style={{color:'#FFF'}}>PDF</TableCell> */}
                        </TableRow>              
                    </TableHead>                
                    <TableBody>
                        {items.map((item, i) => { 
                            granTotal += item.Total;   
                            totalRegistros += 1;                            
                            return (
                                <Fragment key={'prueba-'+i}>                                         
                                    <TableRow key={'datos-'+i} className={classes.tableRowBody}>                                        
                                        <TableCell align="center" className={classes.tableDatos}>{item.strPedido}</TableCell>
                                        <TableCell align="center" className={classes.tableDatos}>{item.strPedidoCliente}</TableCell>
                                        <TableCell align="center" className={classes.tableDatos}>{item.strObra}</TableCell>
                                        <TableCell align="center" className={classes.tableDatos}>
                                            {                    
                                                ('0' + new Date(item.datFechaPedido).getDate()).slice(-2) + 
                                                '/' + ('0' + (new Date(item.datFechaPedido).getMonth()+1)).slice(-2) + 
                                                '/' + new Date(item.datFechaPedido).getFullYear()                  
                                            }
                                        </TableCell>
                                        {/* <TableCell align="center" className={classes.tableDatos}>
                                            {   
                                                ('0' + (new Date(item.datFechaEntrega).getDate()+1)).slice(-2) + 
                                                '/' + ('0' + (new Date(item.datFechaEntrega).getMonth()+1)).slice(-2) + 
                                                '/' + new Date(item.datFechaEntrega).getFullYear()                      
                                            }
                                        </TableCell> */}
                                        <TableCell align="center" className={classes.tableDatos}>{item.strUsuarioVendedor}</TableCell>
                                        <TableCell align="center" className={classes.tableDatos}>{item.strEstatusPedido}</TableCell>
                                        <TableCell align="right" className={classes.tableDatos}>
                                            {                                              
                                                formatterPeso.format(item.Total)   
                                            } 
                                        </TableCell>
                                        <TableCell align="right" className={classes.tableDatos}>
                                            <div className="flex-column" style={{display:'grid', justifyContent:'center'}}>
                                                <ButtonGroup size="sm">
                                                    {/* Detalle */}
                                                    <Tooltip title="Detalle Pedido">
                                                        <button
                                                            id="btnDetalle"
                                                            type="button"                
                                                            className={`btn btn-outline-primary btn-elevate kt-login__btn-primary `}                                                                                                                            
                                                            style={{borderRadius: '20px',align: 'center', marginRight: '5px', padding:'5px'}} 
                                                            onClick={() => {
                                                                setdblPedido(item.dblPedido);
                                                                setDetalle(true);                                                    
                                                            }} 
                                                        >
                                                            <img
                                                                alt="detalle"
                                                                src={toAbsoluteUrl("/media/iconos/small/detalle.png")}                                                                     
                                                            />
                                                            {/* Detalle */}
                                                        </button>   
                                                    </Tooltip>   
                                                    {/* Remision */}
                                                    { item.dblRemision ? 
                                                        (
                                                            <Tooltip title="Detalle Remisión">
                                                                <button
                                                                    id="btnRemision"
                                                                    type="button"                
                                                                    className={`btn btn-outline-warning btn-elevate kt-login__btn-primary `}                                                                        
                                                                    style={{borderRadius: '20px',align: 'center', marginRight: '5px', padding:'5px'}} 
                                                                    onClick={() => {
                                                                        setdblPedido(item.dblPedido);
                                                                        setRemision(true);                                                    
                                                                    }}                                                                               
                                                                >
                                                                    <img
                                                                        alt="remision"
                                                                        src={toAbsoluteUrl("/media/iconos/small/truck.png")}                                                                                 
                                                                    />
                                                                    {/* Remision                                                         */}
                                                                </button> 
                                                            </Tooltip>                     
                                                        ) : null
                                                    } 
                                                    {/* PDF */}
                                                    <Tooltip title="Descarga PDF">
                                                        <PedidoPDF 
                                                            dblPedido={item.dblPedido}  
                                                            strPedido= {item.strPedido} 
                                                            handleClickPDF={showPDF}                                                           
                                                        />  
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </div>

                                        </TableCell>
                                    </TableRow>
                                    {/* <TableRow key={'opciones-'+i}>
                                        <TableCell colSpan={8} >
                                            <div className="flex-column" style={{display:'grid', justifyContent:'center'}}>
                                                <ButtonGroup size="sm">
                                                    
                                                    <button
                                                        id="btnDetalle"
                                                        type="button"                
                                                        className={`btn btn-outline-primary btn-elevate kt-login__btn-primary `}                                                    
                                                        style={{borderRadius: '20px',align: 'center', marginRight: '20px'}}                                                
                                                        onClick={() => {
                                                            setdblPedido(item.dblPedido);
                                                            setDetalle(true);                                                    
                                                        }} 
                                                    >
                                                        <img
                                                            alt="detalle"
                                                            src={toAbsoluteUrl("/media/iconos/small/detalle.png")}                                                                     
                                                        />
                                                        Detalle
                                                    </button> 
                                                    
                                                    { item.dblRemision ? 
                                                        (
                                                            <button
                                                                id="btnRemision"
                                                                type="button"                
                                                                className={`btn btn-outline-warning btn-elevate kt-login__btn-primary `}    
                                                                style={{borderRadius: '20px',align: 'center', marginRight: '20px'}}
                                                                onClick={() => {
                                                                  setdblPedido(item.dblPedido);
                                                                  setRemision(true);                                                    
                                                              }}                                                                               
                                                            >
                                                                <img
                                                                    alt="remision"
                                                                    src={toAbsoluteUrl("/media/iconos/small/truck.png")}                                                                                 
                                                                />
                                                                Remisión
                                                            </button>                      
                                                        ) : null
                                                    } 
                                                    
                                                    <PedidoPDF 
                                                        dblPedido={item.dblPedido}  
                                                        strPedido= {item.strPedido} 
                                                        handleClickPDF={showPDF}                                                           
                                                    />  


                                                </ButtonGroup>
                                            </div>
                                        </TableCell>
                                    </TableRow>                                                                                                                                                           */}
                                </Fragment>
                            );
                        })} 
                        { showDetalle ?  
                            <ModalDetallePedido 
                                handleClose={handleClose} 
                                idPedido={showdblPedido}
                                show={showDetalle} 
                                body={'detalle pedido'} 
                                title={'Detalle del Pedido'}
                            /> : null 
                        }
                        { showRemision ?  
                              <ModalDetallePedidoRemision 
                                  handleClose2={handleClose2}  
                                  idPedido={showdblPedido} 
                                  show={showRemision}                               
                                  body={'detalle Remision'} 
                                  title={'Remisiones Asociadas al pedido'} 
                              /> : null 
                          }  
                    </TableBody>
                    <TableFooter className={classes.tableHead}>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell align='left'className={classes.tableHeadText} style={{color:'#FFF'}} colSpan={5}>Total Registros: {totalRegistros}</TableCell>                                
                            <TableCell className={classes.tableHeadText} style={{color:'#FFF', textAlign:'right'}} >Monto Total:</TableCell>
                            <TableCell className={classes.tableHeadText} style={{color:'#FFF', textAlign:'right'}}>{formatterPeso.format(granTotal)}</TableCell>                                                                                                
                            <TableCell align='left'className={classes.tableHeadText} style={{color:'#FFF'}} colSpan={1}>&nbsp;</TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper> 
                                            
        </div>
      )
  }

}

// export default Pedido