import React, {useEffect, useState}from 'react'
import { Auth } from 'aws-amplify'
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { toAbsoluteUrl } from "../../../_metronic";

// Loading
import CircularProgress from '@material-ui/core/CircularProgress';

// CARD
import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';

import {
    Button,
    Modal,
} from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),         
        color: "#f14258"
    },
    root: {
        width: '100%',
    },
    paper: {      
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    tableRowRemision:{
        background:'#ffe4a9 !important'
    },    
    tableRowPedido:{
        background:'#9a99994d !important'
    },
    leyenda :{
        width:'15px',
        height:'15px',        
        display:'inline-block',
        borderRadius:'20px', 
        marginRight:'5px'
    },
    cardHeader:{
        padding:'5px',
        background:'#ffdcda',
    },
    avatarPedido: {
        backgroundColor: "#f44336",
    },
    avatarRemision: {
        backgroundColor: "#62f436",
        marginRight:'5px'
    },
    contentInfo:{
        fontWeight:'bold'
    },
    cardContent:{
        textAlign:'center', 
        background:'#cacaca',
        padding:'5px'
    },
    cardActionsContainer:{
        lineHeight:'40px'
    },   
    card:{
        marginBottom:'5px',
    },
}));


function obtenerFecha(){
    var getfecha = new Date();
    return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}

export function ModalDetallePedidoRemision (props) {  
    
    var Fecha = new Date();  
    var fechaActual = Fecha.getFullYear()+'-'+('0' + (Fecha.getMonth()+1)).slice(-2)+'-'+Fecha.getDate();
    
    const classes = useStyles();   
    
    // init:Carga de Datos
    let [state, setState] = useState({
        items: [],
        isLoaded: false
    }) 

    function verRemisionPDF(nom){        
        window.open('https://s3.us-east-2.amazonaws.com/compre-remisiones/'+nom);        
    }

    async function consultaRemision() {                

        let strEmail = null

        try{
            strEmail = await Auth.currentUserInfo();  
            strEmail  = strEmail.attributes.email;
        }catch(e){
            // console.log('Error');
        }

        // var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/pedidos';

        let fechaAlta = obtenerFecha();

        // Store Procedure => sp_getRemisionPedido
        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/pedidos/remisiones';        
        var data = {
            "strEmail": strEmail,      
            "datFechaAlta": fechaAlta,
            "dblPedido": props.idPedido            
        };

        console.log(data);
        
        fetch(url, {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then(res => res.json())
        .then(json => {
          console.log(json);
          setState({
            isLoaded: true,
            items: json[0],
          });
        });



    }

    useEffect(() => {        
        consultaRemision();
    }, [props.show])    

    var { isLoaded, items} = state
    
    // Indica el acomodo de las filas en la tabla
    var dblPedidoAnterior = "";
    
    const totalElementosRemision = items.reduce((r, n) => n % 2 ? r : r + 1, 0);
    
        return (    
                      
            <Modal
                show={ state.isLoaded ? props.show : false}                        
                onHide = {()=> props.handleClose2()}                                                  
                dialogClassName="modal-90w"            
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>   
                    {
                        isLoaded ? 
                            <>                                                                          
                                <div className={classes.root}>                    
                                    <Paper className={classes.paper}>                                                            
                                        <Grid container>
                                            <Grid item xs={12} >    
                                                
                                                    {items.map((row, i) => (
                                                        <div key={'contenido-'+i}>
                                                            <Card className={classes.card}>  
                                                                <CardActions className={classes.actions} >
                                                                    <Grid container className={classes.cardActionsContainer}>                                                   
                                                                        <Avatar aria-label="Recipe" className={classes.avatarRemision}>
                                                                            R                
                                                                        </Avatar>
                                                                        <Grid item xs={1} className={classes.contentInfo}>               
                                                                            Remisión:
                                                                        </Grid>
                                                                        <Grid item xs={1}>               
                                                                            {row.strSerie + row.dblNumeroRemision}
                                                                        </Grid>
                                                                        <Grid item xs={1} className={classes.contentInfo}>               
                                                                            Usuario:
                                                                        </Grid>
                                                                        <Grid item xs={3}>               
                                                                            {row.strNombreCompleto}
                                                                        </Grid>
                                                                        <Grid item xs={1} className={classes.contentInfo}>
                                                                            Fecha: 
                                                                        </Grid>
                                                                        <Grid item xs={1}>           
                                                                        {                                                    
                                                                            ('0' + new Date(row.datFechaRemision).getDate()).slice(-2) + 
                                                                            '/' + ('0' + (new Date(row.datFechaRemision).getMonth()+1)).slice(-2) + 
                                                                            '/' + new Date(row.datFechaRemision).getFullYear()  
                                                                        }
                                                                        </Grid>
                                                                        <Grid item xs={1} className={classes.contentInfo}>
                                                                            Ver:
                                                                        </Grid>
                                                                        <Grid item xs={2}>           
                                                                            {
                                                                                row.strArchivoComprobante != null ?
                                                                                    <button
                                                                                        id="btnDescarga"
                                                                                        type="button"                
                                                                                        className={`btn btn-outline-success btn-sm `}  
                                                                                        style={{padding:'2px', borderRadius:'15px'}}                                                                                                              
                                                                                    >
                                                                                        <img
                                                                                            alt="detalle"
                                                                                            src={toAbsoluteUrl("/media/iconos/small/download.png")}    
                                                                                            onClick={() => verRemisionPDF(row.dblRemision+row.strArchivoComprobante)}                                                                              
                                                                                        />
                                                                                    </button>
                                                                                : 'Sin Comprobante'

                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardActions>
                                                            </Card>
                                                        </div>
                                                    ))} 
                                                
                                            </Grid>
                                        </Grid>                              
                                    </Paper>
                                </div>
                                        
                            </>
                        : 
                            <div className={"row justify-content-center"}>
                                <CircularProgress className={classes.progress} color="secondary" size={100}/>      
                            </div>
                    }
                </Modal.Body>
                <Modal.Footer>                       
                    <Button variant="danger" onClick={()=> props.handleClose2()}>Cerrar</Button>                    
                </Modal.Footer>

            </Modal>
            
        )
    
}

