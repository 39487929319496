import React, {Component} from 'react';
import { Auth } from 'aws-amplify';
import { toAbsoluteUrl } from "../../../_metronic";
import Tooltip from '@material-ui/core/Tooltip';

function obtenerFecha(){
    var getfecha = new Date();
    return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);  
}


class ComplementoPagoXML extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],          
            isLoadedXML: false
        };
        this.ComplementoPagoXML = this.ComplementoPagoXML.bind(this);
	} 
 
   
    
    async ComplementoPagoXML  () {

        let strEmail = null
        try{
            strEmail = await Auth.currentUserInfo();  
            strEmail  = strEmail.attributes.email;
            console.log(strEmail);
        }catch(e){
            console.log('Error');
        }

        let fechaAlta = obtenerFecha();

        // var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/complementospago/complementopagopdf';
        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/complementospago/complementopagoxml';
        var data = {
            "strEmail": strEmail,      
            "datFechaAlta": fechaAlta,
            "dblCobranzaTimbradoSAT": this.props.dblCobranzaTimbradoSAT
        };

        console.log(data);
        
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => { 
            console.log(data);
            this.setState({ 
                items: data[0][0],                
                isLoadedXML: true          
            })      
            this.guardarXML();  
        });              
		        
    }  
    
    guardarXML = () => {                   
        const element = document.createElement("a");
        const file = new Blob([this.state.items.strXMLCobranza], {type: 'text/xml'});
        element.href = URL.createObjectURL(file);
        element.download = this.props.strSerieComplementoPago+".xml";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
  
    render() {  
    
        return(
            
            <Tooltip title="Descarga XML">
                <button
                    id="btnFacturaXML"
                    type="button"                
                    className={`btn btn-outline-success btn-elevate kt-login__btn-primary `}                    
                    style={{borderRadius: '20px',align: 'center', marginRight: '5px', padding:'5px'}}        
                    onClick={this.ComplementoPagoXML}
                >
                    <img
                        alt="XML"
                        src={toAbsoluteUrl("/media/iconos/small/xml.png")}
                        // width="40%"                    
                    />                
                </button>
            </Tooltip>

        )          
    }
}

export default ComplementoPagoXML;
