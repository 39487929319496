import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import Factura from "./Factura";
import ComplementoPago from "./ComplementoPago";
import Pedido from "./Pedido";
import Pago from "./Pago";
import PedidoPDF from "./ProPedidoPDF";
import myApp from "./descargaPedidoPDF";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);


export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/factura" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/factura" component={Factura} />
        <Route path="/complemento-pago" component={ComplementoPago} />
        <Route path="/Pedido" component={Pedido} />        
        <Route path="/Pago" component={Pago} />    
        <Route path="/ProPedidoPDF" component={PedidoPDF} />    
        <Route path="/descargaPedidoPDF" component={myApp} />    
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
