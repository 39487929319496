import React, {useEffect, useState}from 'react'
import { Auth } from 'aws-amplify'
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

// Loading
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    Button,
    Modal,
} from "react-bootstrap";

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 11,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const StyledTableHead = withStyles(theme => ({
    head: {
        backgroundColor: '#676666',
        color: '#fff',
    },    
}))(TableRow);

const useStylesTable = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),         
        color: "#f14258"
    },
    root: {
        width: '100%',
    },
    paper: {      
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },    
    table: {
        minWidth: 650,
    },
}));

const formatterPeso = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
  });

function obtenerFecha(){
    var getfecha = new Date();
    return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}

export function ModalDetallePedido (props) {          
    const classesTable = useStylesTable();   

    // init:Carga de Datos
    let [state, setState] = useState({
        items: [],
        itemsCliente: [],
        itemsPlanta: [],
        // subTotal : null,
        isLoaded: false
    }) 

    // let [ stateTotalValues, setTotalValues] = useState(null) 

    async function consultaDetallePedido(fechaInicial, fechaFinal) {                

        let strEmail = null

        try{
            strEmail = await Auth.currentUserInfo();  
            strEmail  = strEmail.attributes.email;
        }catch(e){
            // console.log('Error');
        }

        let fechaAlta = obtenerFecha();

        // store Procedure => sp_getPedidoDetalle
        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/pedidos/detalle';        
        var data = {
            "strEmail": strEmail,      
            "datFechaAlta": fechaAlta,
            "dblPedido": props.idPedido            
        };
        console.log(data);
        
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            // console.log(json);
            setState({...state,
                isLoaded: true,
                items: json[0],  //Pedido Detalle
                itemsCliente: json[1],  //Pedido de Cliente
                itemsPlanta: json[2],  //Pedido de Planta
            });
        });



    }

    useEffect(() => {        
        consultaDetallePedido();
    }, [props.showDetalle]) 

    var { isLoaded, items, itemsCliente, itemsPlanta} = state

    // Suma de Totales de Pedido Clientes
    let granSubtotal = itemsCliente.reduce(function(prev, current) {
        return prev + +((current.intCantidad * current.dblPrecioUnitario).toFixed(2))        
    }, 0);

    let granIva = itemsCliente.reduce(function(prev, current) {
        return prev + +((current.intCantidad * current.dblIVA).toFixed(2))        
    }, 0);
    
    let granTotal = itemsCliente.reduce(function(prev, current) {
        return prev + +(((current.intCantidad*current.dblPrecioUnitario)+(current.intCantidad*current.dblIVA)).toFixed(2))        
    }, 0);    

    
    const totalElementosCliente = itemsCliente.reduce((r, n) => n % 2 ? r : r + 1, 0);
    const totalElementosPlanta = itemsPlanta.reduce((r, n) => n % 2 ? r : r + 1, 0);


        
    return (                             
            
        <Modal
            show={props.show}                        
            onHide = {()=> props.handleClose()}                                                  
            dialogClassName="modal-90w"            
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>             

            {
                isLoaded ? 
                    <>
                        <div className={classesTable.root}>                    
                            <Paper className={classesTable.paper}>
                                <Table className={classesTable.table} size="small">
                                    <TableHead className={'text-center'}>
                                        <StyledTableHead>
                                            <th align="center">No. Pedido</th>
                                            <th align="center">Fecha del Pedido</th>                                
                                            <th align="center">Fecha de Compromiso</th>                
                                            <th align="center">Dirección</th>
                                            <th align="center">Orden de Compra</th>
                                            <th align="center">Obra</th>
                                            <th align="center">Vendedor</th>                                
                                        </StyledTableHead>
                                    </TableHead>                
                                    <TableBody>                                
                                        {items.map( (row, i) => (                                      
                                            <StyledTableRow key={'detalle-'+i}>
                                                <StyledTableCell align="center">{row.strPedido}</StyledTableCell>
                                                <StyledTableCell align="center">{
                                                    ('0' + new Date(row.datFechaPedido).getDate()).slice(-2) + 
                                                    '/' + ('0' + (new Date(row.datFechaPedido).getMonth()+1)).slice(-2) + 
                                                    '/' + new Date(row.datFechaPedido).getFullYear()                                                                                        
                                                }</StyledTableCell>
                                                <StyledTableCell align="center">{
                                                    ('0' + (new Date(row.datFechaEntrega).getDate()+1)).slice(-2) + 
                                                    '/' + ('0' + (new Date(row.datFechaEntrega).getMonth()+1)).slice(-2) + 
                                                    '/' + new Date(row.datFechaEntrega).getFullYear()                                            
                                                }</StyledTableCell>
                                                <StyledTableCell align="center">{row.strDireccionEntrega}</StyledTableCell>
                                                <StyledTableCell align="center">{row.strPedidoCliente}</StyledTableCell>
                                                <StyledTableCell align="center">{row.strObra}</StyledTableCell>
                                                <StyledTableCell align="center">{row.strUsuarioVendedor}</StyledTableCell>                
                                            </StyledTableRow>                
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </div>
                                                        
                        <div className={classesTable.root}>
                            <h3>Pedido de Planta</h3>
                            <Paper className={classesTable.paper}>
                                <Table className={classesTable.table} size="small"  >
                                    <TableHead>
                                        <StyledTableHead >
                                            <th className={'text-left'}>Producto / Servicio</th>
                                            <th className={'text-center'}>Cantidad</th>
                                            {/* <th>Entregado</th>
                                            <th>Faltante</th> */}
                                            {/* <th>Produce</th>
                                            <th>Unidad Medida:</th>
                                            <th>Largo</th>
                                            <th>Ancho</th>
                                            <th>Alto</th>
                                            <th>Nota</th>
                                            <th>Empresa que Surte</th> */}
                                        </StyledTableHead>
                                    </TableHead>
                                    <TableBody>
                                        {itemsPlanta.map( (row, i) => (  
                                            <StyledTableRow    key={'Planta'+ i}>                            
                                                <StyledTableCell component="th" scope="row" >{row.strProducto} </StyledTableCell>
                                                <StyledTableCell align="center" >{row.dblCantidad}</StyledTableCell>
                                                
                                                {/* <StyledTableCell align="center">Null</StyledTableCell>
                                                <StyledTableCell align="center">Null</StyledTableCell> */}
                                                {/* <StyledTableCell align="center">{row.datFechaProduccion}</StyledTableCell>
                                                <StyledTableCell align="center">{row.strNombreCorto}</StyledTableCell>
                                                <StyledTableCell align="center">{row.dblLargo}</StyledTableCell>
                                                <StyledTableCell align="center">{row.dblAncho}</StyledTableCell>
                                                <StyledTableCell align="center">{row.dblAlto}</StyledTableCell>
                                                <StyledTableCell align="center">{row.dblMedidas}</StyledTableCell>
                                                <StyledTableCell align="center">{row.strFranquicia}</StyledTableCell> */}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                    <TableHead>
                                        <StyledTableHead>
                                        <th align="center" colSpan="2">Cantidad de Registros: {totalElementosPlanta}</th>                                                                                                   
                                        </StyledTableHead>
                                    </TableHead>
                                </Table>  
                            </Paper>

                            <h3>Pedido del Cliente</h3>
                            <Paper className={classesTable.paper}>
                                <Table className={classesTable.table} size="small">
                                    <TableHead >
                                        <StyledTableHead>
                                            <th className={'text-center'}>Producto / Servicio</th>
                                            <th className={'text-center'}>Cantidad</th>
                                            <th className={'text-center'}>Unidad Medida</th>
                                            <th className={'text-right'}>Precio Unitario</th>
                                            <th className={'text-right'}>Subtotal</th>
                                            <th className={'text-right'}>IVA</th>
                                            <th className={'text-right'}>Total</th>                                    
                                        </StyledTableHead>
                                    </TableHead>
                                    <TableBody>                                                                    
                                        {itemsCliente.map( (row, i) => {
                                            let iva = (row.intCantidad * row.dblIVA).toFixed(2)
                                            let subTotal = (row.intCantidad * row.dblPrecioUnitario).toFixed(2)
                                            let total = ((row.intCantidad*row.dblPrecioUnitario)+(row.intCantidad*row.dblIVA)).toFixed(2)                                    
                                            return (                                        
                                                <StyledTableRow    key={'Cliente-'+i}>                                                                
                                                    <StyledTableCell component="th" scope="row">{row.strProducto}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.intCantidad}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.UnidadMedida}</StyledTableCell>
                                                    <StyledTableCell align="right" style={{padding:'5px'}}>{row.dblPrecioUnitario}</StyledTableCell>
                                                    <StyledTableCell align="right" style={{padding:'5px'}}>{formatterPeso.format(subTotal)}</StyledTableCell>
                                                    <StyledTableCell align="right" style={{padding:'5px'}}>{formatterPeso.format(iva)}</StyledTableCell>
                                                    <StyledTableCell align="right" style={{padding:'5px'}}>{formatterPeso.format(total)}</StyledTableCell>                                                                                                
                                                                                                                                    
                                                </StyledTableRow>
                                            )                                    
                                        })}                                
                                    </TableBody>
                                    <TableHead>
                                        <StyledTableHead >
                                            <th colSpan="3">Cantidad de Registros: {totalElementosCliente}</th>
                                            <th className={'text-right'}>Totales:</th>
                                            <th className={'text-right'} style={{ paddingRight:'5px'}}>{formatterPeso.format(granSubtotal)}</th>
                                            <th className={'text-right'} style={{ paddingRight:'5px'}}>{formatterPeso.format(granIva)}</th>
                                            <th className={'text-right'} style={{ paddingRight:'5px'}}>{formatterPeso.format(granTotal)}</th>                                                                                                            
                                        </StyledTableHead>
                                    </TableHead>
                                </Table>  
                            </Paper>
                        </div>
                    
                    </>                                
                : 
                    <div className={"row justify-content-center"}>
                        <CircularProgress className={classesTable.progress} color="secondary" size={100}/>      
                    </div>
            }
            </Modal.Body>
            <Modal.Footer>                       
                <Button variant="danger" onClick={()=> props.handleClose()}>Cerrar</Button>
                {/* <Button variant="primary">Guardar</Button> */}
            </Modal.Footer>

        </Modal>
        
    )
    
}

