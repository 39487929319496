import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
//import { login } from "../../crud/auth.crud";
import { Auth } from 'aws-amplify';
import { toAbsoluteUrl } from "../../../_metronic";


function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  const [localUser, setlocalUser] = React.useState('');
  let history = useHistory();

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  async function SignIn(username,password) {
    try {
        const user = await Auth.signIn(username, password);                              
        try {
            localStorage.setItem('usuario', user.attributes.email);
        } catch(e) {          
            console.log("Error al guardar el usuario en el localStorage")          
        }        
        return user;
    } catch (error) {        
        return null;
    }
  }

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logo_2.png")}
                width="80%"
                // style={{marginBottom:'20px'}}                
            />
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
            </h3>            
          </div>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                /*
                login(values.email, values.password)
                  .then(({ data: { accessToken } }) => {
                    disableLoading();
                    console.log(accessToken);
                    props.login(accessToken);
                  })
                  .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                      })
                    );
                  });
                */
                
                SignIn(values.email,values.password)
                  .then(data => {
                    //console.log(data);
                    disableLoading();
                    
                    if(data.challengeName !== "undefined" && data.challengeName === "NEW_PASSWORD_REQUIRED"){
                      // console.log("se necesita cambio y se manda " + data.challengeName);
                      history.push({
                        pathname: '/auth/registration',
                        //search: '?query=abc',
                        state: { 
                          detail: data.challengeParam,
                          user: values.email,
                          pass: values.password
                        }
                      });
                      //props.login(data.challengeName);
                    }else{
                      // console.log("no se necesita cambio");
                      props.login(data.signInUserSession.accessToken.jwtToken);
                    }
                    //console.log(data.signInUserSession.accessToken.jwtToken);
                    //access-token-8f3ae836da744329a6f93bf20594b5cc
                    
                  })
                  .catch(data => {
                    // console.log(data);
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                      })
                    );
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">
                      Use su cuenta  y su contraseña{" "} para continuar.
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Contraseña"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="kt-login__actions">
                  <Link
                    to="/auth/forgot-password"
                    className="kt-link kt-login__link-forgot"
                  >
                    {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
                  </Link>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Iniciar Sesión
                  </button>
                  
                </div>
              </form>
              
            )}
          </Formik>
                  
                
          {/* <div className="kt-login__divider">
            <div className="kt-divider">
              <span />
              <span>OR</span>
              <span />
            </div>
          </div>

          <div className="kt-login__options">
            <Link to="http://facebook.com" className="btn btn-primary kt-btn">
              <i className="fab fa-facebook-f" />
              Facebook
            </Link>
            <Link to="http://twitter.com" className="btn btn-info kt-btn">
              <i className="fab fa-twitter" />
              Twitter
            </Link>
            <Link to="google.com" className="btn btn-danger kt-btn">
              <i className="fab fa-google" />
              Google
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);
