import React, {useEffect, useState}from 'react'
import { Auth } from 'aws-amplify'
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import {
    Button,
    Modal,
} from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {      
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },    
    table: {
        minWidth: 650,
    },
}));

const StyledTableHead = withStyles(theme => ({
    head: {
        backgroundColor: '#676666',
        color: '#fff',
    },    
}))(TableRow);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 11,
    },
}))(TableCell);

function obtenerFecha(){
    var getfecha = new Date();
    return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}

export function ModalDetalleFacturaRemision (props) {          
    const classes = useStyles();  
    
    // Carga de Datos
    let [state, setState] = useState({
        items: [],       
        isLoaded: false
    }) 

    async function consultaDetalleFacturaRemision() {               

        let strEmail = null

        try{
            strEmail = await Auth.currentUserInfo();  
            strEmail  = strEmail.attributes.email;
        }catch(e){
            // console.log('Error get User');
        }

        let fechaAlta = obtenerFecha();

        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/facturacion/remisionfactura';
        var data = {
            "strEmail": strEmail,      
            "datFechaAlta": fechaAlta,
            "dblFactura": props.dblFactura
        };

        // console.log(data);
        
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            // console.log(json);
            setState({...state,
                isLoaded: true,
                items: json[0],  //Factura Detalle                
            });
        });



    }

    useEffect(() => {        
        consultaDetalleFacturaRemision();
    }, []) 

    var { isLoaded, items} = state

    return (                             
            
        <Modal
            show={props.show}                        
            onHide = {()=> props.handleCierreModalRemision()}                                                  
            dialogClassName="modal-90w"            
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>      
  
                <div className={classes.root}>
                    <h3>Remisiones</h3>
                    <Paper className={classes.paper}>
                        <Table className={classes.table} size="small">
                            <TableHead className={'text-center'}>
                                <StyledTableHead>                                    
                                    <th align="center">REMISIÓN</th>
                                    <th align="center">PEDIDO</th>
                                    <th align="center">USUARIO</th>                                    
                                    <th align="center">FECHA</th>                                                                       
                                </StyledTableHead>
                            </TableHead>                
                            <TableBody>       
                                {items.map( (row, i) => (      
                                    <StyledTableRow key={'detalleRemision-'+i}>
                                            <StyledTableCell align="center">{row.strRemision}</StyledTableCell>
                                            <StyledTableCell align="center">{row.strPedido}</StyledTableCell>
                                            <StyledTableCell align="center">{row.strUsuario}</StyledTableCell>
                                            <StyledTableCell align="center">{                                            
                                                ('0' + new Date(row.datFechaRemision).getDate()).slice(-2) + 
                                                '/' + ('0' + (new Date(row.datFechaRemision).getMonth()+1)).slice(-2) + 
                                                '/' + new Date(row.datFechaRemision).getFullYear()      
                                            }</StyledTableCell>                                            
                                    </StyledTableRow>                      
                                ))} 
                            </TableBody>
                        </Table>
                    </Paper>
                </div>




            
            </Modal.Body>
            <Modal.Footer>                       
                <Button variant="danger" onClick={()=> props.handleCierreModalRemision()}>Cerrar</Button>                
            </Modal.Footer>

        </Modal>
        
    )

}