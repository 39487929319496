import React, { useState, useEffect, useRef, Fragment } from "react";
import { Auth } from 'aws-amplify'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { toAbsoluteUrl } from "../../../_metronic";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Link, useHistory, Redirect } from "react-router-dom";
import {  
  ButtonGroup,  
} from "react-bootstrap";
import { Form } from "react-bootstrap";

// Iconos
import CircularProgress from '@material-ui/core/CircularProgress';


// Filtro
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Modales
import {ModalDetalleFactura} from "../Components/modalDetalleFactura";
import {ModalDetalleFacturaRemision} from "../Components/modalDetalleFacturaRemision";
import {ModalDetalleFacturaPedido} from "../Components/modalDetalleFacturaPedido";
import FacturaPDF from "./ProFacturaPDF";
import FacturaXML from "./ProFacturaXML";
// import FacturaMasivo from "./ProFacturaMasivo";

import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),         
        color: "#f14258"
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    filtro: {
        padding: theme.spacing(1, 1),
        marginBottom: theme.spacing(1),      
    },
    table: {
        minWidth: 650,
        border:'5px solid #d41f36',
        borderRadius:'10px',
        padding:'0px'
    },
    tableHead:{
        backgroundColor: '#d41f36',
        color: '#fff',
        textAlign: 'center',
        margin:'0px',
        padding:'0px',
    },
    tableHeadRow:{
        padding:'0px',
        margin:'0px'
    },
    tableHeadText:{
        fontSize:'12px',
        fontWeight:'bold',
        padding:'0px',
    },
    tableRowBody:{
        '&:nth-of-type(odd)': {
            backgroundColor: '#f1f1f1',
        },
    },
    tableDatos:{
        borderBottom:'0px',
        padding:'0px'
    },
    BtnPurple:{        
        border: '1px solid black',
        borderColor: '#c01def',
        color: '#c01def',
        borderRadius: '30px',
        backgroundColor: '#FFF',
        "&:hover": {      
            backgroundColor: '#c01def',
            color: '#FFF',
        }
        
    }
    
}));

// const BtnPurple = withStyles(theme => ({
  
// }))(Button);

const formatterPeso = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
});

function obtenerFecha(){
    var getfecha = new Date();
    return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}

export default function ComplementoPago() {  

  let refValueDateInicial = useRef();
  let refValueDateFinal = useRef();  
  let history = useHistory();
  // Variables filtro busqueda fechas
  var fecha = new Date();
  var fechaInicioMes = fecha.getFullYear()+'-'+('0' + (fecha.getMonth()+1)).slice(-2)+'-01';
  var fechaActual = fecha.getFullYear()+'-'+('0' + (fecha.getMonth()+1)).slice(-2)+'-'+('0' + (fecha.getDate())).slice(-2);
  
  const classes = useStyles();   

  // Carga de Datos
  let [state, setState] = useState({
      items: [],
      isLoaded: false,
      loadPDF: false
  })
  
  const [showFechaInit, setFechaInit] = useState(false);
  const [showFechaFin, setFechaFin] = useState(false);

  // Config checkbox
  const newValue = {"factura":[], "dblRemision":[], "strArchivoComprobante":[]};
  const [checkedItems, setCheckedItems] = React.useState({});
  const handleChange = (event) => {
      // updating an object instead of a Map
      setCheckedItems({...checkedItems, [event.target.name] : event.target.checked });
    //   console.log("checkedItems: ", checkedItems);
  }

  // Modales
  const [showDetalle, setDetalle] = useState(false);
  const [showDetalleRemision, setDetalleRemision] = useState(false);
  const [showDetallePedido, setDetallePedido] = useState(false);
  
  const [showdblFactura, setdblFactura] = useState();

  // Cierre Modales
  function handleCierreModal() {    
      setDetalle(!showDetalle);        
  };
  function handleCierreModalRemision() {    
      setDetalleRemision(!showDetalleRemision);        
  };
  function handleCierreModalPedido() {    
      setDetallePedido(!showDetallePedido);        
  };


  // Filtrar Factura
  function customSetFiltrarFactura () {       
      setState({ isLoaded: false });
      setFechaInit(refValueDateInicial.current.value);
      setFechaFin(refValueDateFinal.current.value);      
      consultaFactura(refValueDateInicial.current.value,refValueDateFinal.current.value)
  }

  
    // Consulta Factura
    async function consultaFactura(fechaInicial, fechaFinal) {                 

        var fechaFacturaInicial = "";
        var fechaFacturaFinal = "";
        var today = new Date();    
        if (fechaInicial === undefined || fechaInicial === ""){
            fechaFacturaInicial = today.getFullYear()+'-'+('0' + (today.getMonth()+1)).slice(-2)+'-01 00:00:00';      
        } else {
            fechaFacturaInicial = fechaInicial;
        }
        if (fechaFinal === undefined || fechaFinal === ""){
            fechaFacturaFinal = fechaActual +" 23:59:59";
        } else {
            fechaFacturaFinal = fechaFinal+" 23:59:59";
        }
    
        let userName = null;
        
        if (localStorage.getItem('usuario') != null){
            userName = localStorage.getItem('usuario');                   
        }else{
            console.log("No hay usuario almacenado en el localstorage");
            history.push({
                pathname: '/logout',               
            }); 
        }

        let fechaAlta = obtenerFecha();
            
        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/facturacion/facturas';        
        var data = {
            "strEmail": userName,           
            "datFechaFacturaInicio": fechaFacturaInicial,
            "datFechaFacturaFin": fechaFacturaFinal,
            "datFechaAlta": fechaAlta            
        };

        // console.log(data)
    
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {      
            // console.log(json[0]);      
            setState({
                isLoaded: true,
                items: json[0],
            });
        });
    }

    useEffect(() => {  
        verificarUser();                        
        consultaFactura();        
    },[]);   
    
    async function verificarUser(){
        let response = null;
        try{
            response = await Auth.currentUserInfo(); 
           
            if(JSON.stringify(response) == JSON.stringify({}) ){
                // console.log('Usuario no existe');
                history.push({
                    pathname: '/logout',               
                }); 
            }
        }catch(e){
            // console.log('Error get user')
            history.push({
                pathname: '/logout',               
            });                             
        }
    }
  
    function checkboxActivos(){
       
        // Validación        
        if(newValue["factura"].length === 0){
            alert("Seleccione al menos una casilla para proceder con la descarga de documentos");
            return false;
        }
        if(newValue["factura"].length >= 11){
            alert("Solo se permite descargar un máximo de 10 facturas");
            return false;
        }
        
        //función para descargar el .zip con los xml y pdf de las facturas.
        let nombreZip = uuidv4()+'.zip';
        // console.log('nombre de zip -> '+nombreZip);

        var arrObjArchivos = [];

        var i = 0;
        // console.log(newValue);
        newValue['factura'].forEach(data => {   
                     
            arrObjArchivos.push(
                {
                    "name": data,
                    "type": "pdf",
                    "fileName": data+".pdf",
                    "key": data+".pdf"
                }
            );
            arrObjArchivos.push(
                {  
                    "name": data,
                    "type": "xml",
                    "fileName": data+".xml",
                    "key": data+".xml"
                }
            );
            

            if (newValue['strArchivoComprobante'][i] != null) {
                var arrComprobanteRemisiones = newValue['strArchivoComprobante'][i].split(',');

                arrComprobanteRemisiones.forEach(val => {
                    
                    arrObjArchivos.push(
                        {  
                            "name": data,
                            "type": "evidencia",
                            "fileName": val,
                            "key": val
                        }
                    );

                });
            }
            i = i+1;
        });

        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/facturacion/descargamasiva';
        let dataEnvio =
        {
            "nombreZip": nombreZip,
            "archivos": arrObjArchivos
        };

        //console.log(dataEnvio);

        // return false;
        
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(dataEnvio), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            //    console.log(json);
            /*          
            setState({
                loadPDf: false                  
            });*/
           window.open("https://compre-s3descargamasiva.s3.us-east-2.amazonaws.com/"+nombreZip, "Download");
        });
        //alert(newValue);
    }    

    var { isLoaded, items, loadPDF} = state

    var granSubtotal = 0;
    var granIva = 0;
    var granTotal = 0;    
    var totalRegistros = 0;    
    var strEstatusFactura = '';

    const intDiasPorVencer = 7;    

    if (!isLoaded){        
        return(      
            <div className={"row justify-content-center"}>
                <CircularProgress className={classes.progress} color="secondary" size={100}/>      
            </div>
        )
    } else { 

        return (
            <div>
                {/* FILTRO */}
                <Paper className={classes.filtro}>
                    <Typography variant="h5" component="h3">          
                        <form className={classes.container} noValidate>
                            <div >
                                <Button variant="contained" size="large" style={{ backgroundColor: '#e6e5eb' }} onClick={() => customSetFiltrarFactura()}>
                                    <SearchIcon style={{ fontSize: 28}}/>
                                </Button>              
                            </div>
                          
                            <TextField                
                                inputRef={refValueDateInicial}
                                id="fechaFacturaInicio"
                                label="Fecha Factura Inicial"
                                type="date"                
                                defaultValue={(showFechaInit ? showFechaInit: fechaInicioMes)}
                                className={classes.textField}
                                style = {{width: '42%'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                inputRef={refValueDateFinal}
                                id="fechaFacturaFin"
                                label="Fecha Factura Final"
                                type="date"
                                defaultValue={(showFechaFin ? showFechaFin: fechaActual)}
                                className={classes.textField}
                                style = {{width: '42%'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>                              
                    </Typography>
                </Paper>  
                {/* END FILTRO */}  

                <Paper className={classes.paper} style={{marginBottom:'5px'}}>
                    <Table className={classes.table} size="small">
                    
                        <TableHead className={classes.tableHead}>
                            <TableRow className={classes.tableHeadRow}>
                                <TableCell align='center'className={classes.tableHeadText} style={{color:'#FFF'}}>
                                    <Form.Group controlId="formBasicChecbox" style={{padding:'0px', margin:'0px'}}>
                                        <Form.Check 
                                            type="checkbox"                                         
                                            onChange={(event) => {
                                                // console.log(event.target);                                             
                                                // newValue.splice(0,newValue.length);                                                                                                
                                                newValue['factura'].splice(0,newValue['factura'].length);
                                                newValue['dblRemision'].splice(0,newValue['dblRemision'].length);
                                                newValue['strArchivoComprobante'].splice(0,newValue['strArchivoComprobante'].length);
                                                // console.log(newValue);
                                                {items.map((item, i) => {     
                                                    var inputCheckbox = document.getElementById('chechbox-'+item.dblFactura);                                       
                                                    // console.log(inputCheckbox);
                                                    if (event.target.checked) {                                                
                                                        
                                                        if(!document.getElementById('chechbox-'+item.dblFactura).hasAttribute("disabled")){
                                                            document.getElementById('chechbox-'+item.dblFactura).checked = true;  
                                                            // newValue.push(item.strArchivoFactura);
                                                            newValue['factura'][i] = item.strArchivoFactura;
                                                            newValue['dblRemision'][i] = item.dblRemision2;
                                                            newValue['strArchivoComprobante'][i] = item.strArchivoComprobante;
                                                        }                                                    
                                                        
                                                    } else {                                                
                                                        document.getElementById('chechbox-'+item.dblFactura).checked = false;
                                                        // newValue.splice(newValue.indexOf(item.strArchivoFactura), 1);
                                                        newValue['factura'].splice(newValue['factura'].indexOf(item.strArchivoFactura), 1);
                                                        newValue['dblRemision'].splice(newValue['dblRemision'].indexOf(item.dblRemision2), 1);
                                                        newValue['strArchivoComprobante'].splice(newValue['strArchivoComprobante'].indexOf(item.strArchivoComprobante), 1);
                                                    }
                                                })};                                                       
                                                // console.log(newValue);
                                            }}
                                        />
                                    </Form.Group>                                
                                </TableCell>
                                <TableCell align='center'className={classes.tableHeadText} style={{color:'#FFF'}}>Factura</TableCell>
                                <TableCell align='center'className={classes.tableHeadText} style={{color:'#FFF'}}>Fecha</TableCell>
                                <TableCell align='center'className={classes.tableHeadText} style={{color:'#FFF'}}>Estatus</TableCell>
                                <TableCell align='center'className={classes.tableHeadText} style={{color:'#FFF'}}>Moneda</TableCell>
                                <TableCell align='right' className={classes.tableHeadText} style={{color:'#FFF'}}>Importe</TableCell>                                
                                <TableCell align='right' className={classes.tableHeadText} style={{color:'#FFF'}}>Descuento</TableCell>
                                <TableCell align='right' className={classes.tableHeadText} style={{color:'#FFF'}}>Subtotal</TableCell>
                                <TableCell align='right' className={classes.tableHeadText} style={{color:'#FFF'}}>Iva</TableCell>                                
                                <TableCell align='right' className={classes.tableHeadText} style={{color:'#FFF'}}>Total</TableCell>         
                                <TableCell align='center' className={classes.tableHeadText} style={{color:'#FFF'}}>Soporte</TableCell>
                                <TableCell align='center' className={classes.tableHeadText} style={{color:'#FFF'}}>Descargas</TableCell>
                            </TableRow>      
                        </TableHead>       
                        <TableBody>                                       
                            {items.map((item, i) => {
                                granSubtotal += item.Subtotal;
                                granIva += item.Iva;
                                granTotal += item.Total;
                                totalRegistros += 1;                                                                    

                                // Facturas Vencidas, por Vencer o Pagada
                                var getFechaFactura = new Date(item.Fecha); 
                                var getFechaHoy = new Date();                                                                

                                var diferenciaFecha =  getFechaHoy.getTime() - getFechaFactura.getTime();                                                                
                                var datDiasTranscurridosFactura = Math.round(diferenciaFecha/ (1000*60*60*24));
                                
                                if(item.Estatus == 'COBRADA'){
                                    var strEstatus = 'PAGADA';
                                } else {
                                    if(datDiasTranscurridosFactura <= item.dblCondicionesPagoDias){                                                                        
                                        if((item.dblCondicionesPagoDias - datDiasTranscurridosFactura)<= intDiasPorVencer){
                                            var strEstatus = 'POR VENCER';
                                        }else {
                                            var strEstatus = 'A TIEMPO';
                                        }
                                        
                                    } else {
                                        var strEstatus = 'VENCIDA';
                                    }
                                }                                                                
                               
                                return (
                                    <Fragment key={'prueba-'+i}>                                         
                                        <TableRow key={'datos-'+i} className={classes.tableRowBody} >
                                            <TableCell align="center"  className={classes.tableDatos}>
                                                <Form.Group controlId="formBasicChecbox" style={{margin:'0px'}}>
                                                    <Form.Check 
                                                        type="checkbox"    
                                                        id={`chechbox-${item.dblFactura}`}                                                 
                                                        name={`chechbox-${item.dblFactura}`}                                                   
                                                        onChange={(event) => {
                                                            // console.log(event.target.checked);
                                                            if (event.target.checked) {
                                                                // newValue.push(item.strArchivoFactura);                                                                
                                                                newValue['factura'].push(item.strArchivoFactura);
                                                                newValue['dblRemision'].push(item.dblRemision2);
                                                                newValue['strArchivoComprobante'].push(item.strArchivoComprobante);
                                                            } else {
                                                                // newValue.splice(newValue.indexOf(item.strArchivoFactura), 1);
                                                                // console.log(newValue['factura'].indexOf(item.strArchivoFactura));                                                                                                                                
                                                                newValue['factura'].splice(newValue['factura'].indexOf(item.strArchivoFactura), 1);
                                                                newValue['dblRemision'].splice(newValue['dblRemision'].indexOf(item.dblRemision2), 1);
                                                                newValue['strArchivoComprobante'].splice(newValue['strArchivoComprobante'].indexOf(item.strArchivoComprobante), 1);
                                                            }       
                                                            // console.log(newValue);                                                                 
                                                        }}
                                                        value={item.dblFactura}   
                                                        disabled={item.strXMLFactura == null  ? 'disabled': null}                                            
                                                    />
                                                </Form.Group>                                               
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableDatos}> 
                                                {item.Factura}                                           
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableDatos}> 
                                                {
                                                    ('0' + (new Date(item.Fecha).getDate()+1)).slice(-2) + 
                                                    '/' + ('0' + (new Date(item.Fecha).getMonth()+1)).slice(-2) + 
                                                    '/' + new Date(item.Fecha).getFullYear()                                          
                                                }
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableDatos} > 
                                                
                                                <button 
                                                    id="btnEstatus"                                                    
                                                    className={`btn btn-elevate kt-login__btn-primary `}                                                                                       
                                                    style={{fontSize:'11px',borderRadius: '20px', padding:'2px',background: strEstatus == 'PAGADA' ? '#85ff9a' : strEstatus == 'VENCIDA' ? '#fdc8c8' : strEstatus == 'POR VENCER' ? '#fdf9c8' : null}}>
                                                {                                                    
                                                    strEstatus

                                                }
                                                </button>
                                                
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableDatos}> 
                                                {item.strMoneda}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableDatos}> 
                                                {                                            
                                                    formatterPeso.format(item.dblImporte)   
                                                }
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableDatos}> 
                                                {                                        
                                                    formatterPeso.format(item.dblDescuento)                                           
                                                }
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableDatos}> 
                                                {                                         
                                                    formatterPeso.format(item.Subtotal)    
                                                }
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableDatos}> 
                                                {                                                   
                                                    formatterPeso.format(item.Iva)    
                                                }
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableDatos}> 
                                                {
                                                    formatterPeso.format(item.Total)
                                                }
                                            </TableCell>
                                            <TableCell align="right" className={classes.tableDatos} style={{padding:'0px'}}>
                                                <div className="flex-column" style={{display:'grid', justifyContent:'center',padding:'0px'}}>
                                                    <ButtonGroup size="sm" style={{padding:'0px'}}>
                                                        {/* REMISION 
                                                        {
                                                            item.dblRemision1 ?
                                                                <Tooltip title="Detalle Remisión">
                                                                    <button                                                            
                                                                        id="btnDetalleRemision"
                                                                        type="button"                
                                                                        className={`btn btn-outline-warning btn-elevate kt-login__btn-primary `}    
                                                                        // style={{borderRadius: '20px',align: 'center', marginRight: '20px'}}
                                                                        style={{borderRadius: '20px',align: 'center', marginRight: '5px', padding:'5px'}} 
                                                                        // onClick={() => setDetalleRemision(true)}
                                                                        onClick={() => {
                                                                        setdblFactura(item.dblFactura);
                                                                        setDetalleRemision(true);                                                    
                                                                    }} 
                                                                    >
                                                                        <img
                                                                            alt="Remision"
                                                                            src={toAbsoluteUrl("/media/iconos/small/truck.png")}
                                                                            // width="40%"                    
                                                                        />
                                                        
                                                                    </button> 
                                                                </Tooltip>                                      
                                                            : null
                                                        }
                                                        */}
                                                        {/* Pedido */}         
                                                        {
                                                            item.dblRemision1 ? 
                                                                <Tooltip title="Detalle Pedido">
                                                                    <button
                                                                        // style={{marginRight: '20px', borderRadius:'20px'}}
                                                                        style={{borderRadius: '20px',align: 'center', marginRight: '5px', padding:'5px'}} 
                                                                        // className={classes.BtnPurple,'btn btn-elevate kt-login__btn-primary'}
                                                                        className={classes.BtnPurple}
                                                                        id="btnDetallePedido"
                                                                        type="button"                                                            
                                                                        onClick={() => {
                                                                        setdblFactura(item.dblFactura);
                                                                        setDetallePedido(true);                                                    
                                                                    }} 
                                                                    >
                                                                        <img
                                                                            alt="Remision"
                                                                            src={toAbsoluteUrl("/media/iconos/small/order.png")}
                                                                            // width="40%"                    
                                                                        />
                                                                        {/* Pedido */}
                                                                    </button>
                                                                </Tooltip>
                                                            :null
                                                        }     
                                                        
                                                    </ButtonGroup>
                                                </div>
                                            </TableCell> 
                                            <TableCell align="right" className={classes.tableDatos} style={{padding:'0px'}}>
                                                <div className="flex-column" style={{display:'grid', justifyContent:'center',padding:'0px'}}>
                                                    <ButtonGroup size="sm" style={{padding:'0px'}}>
                                                        {/* PDF */}

                                                        {item.strXMLFactura ?
                                                         
                                                            !loadPDF ? 
                                                                <FacturaPDF 
                                                                    strFactura={item.Factura}   
                                                                    dblFactura = {item.NFactura}
                                                                    strArchivoFactura = {item.strArchivoFactura}                                                                        
                                                                />
                                                                
                                                            :  <CircularProgress className={classes.progress} />
                                                        : null}
                                                        {/* XML */}
                                                        {item.strXMLFactura  && item.intEstatusFactura !=4 ?
                                                            <FacturaXML 
                                                                strFactura={item.Factura}                                                   
                                                                dblFactura = {item.NFactura} 
                                                                strArchivoFactura = {item.strArchivoFactura}                                                                              
                                                            />
                                                            :null
                                                        }                                                
                                                    </ButtonGroup>
                                                </div>
                                            </TableCell>
                                        </TableRow>                                                                                                                                                          
                                    </Fragment>
                                );
                            })} 
                            { showDetalle ?  
                                <ModalDetalleFactura 
                                    handleCierreModal={handleCierreModal} 
                                    dblFactura={showdblFactura}                                    
                                    show={showDetalle}                                           
                                    title={'Detalle de Factura'}
                                /> : null 
                            }  
                            { showDetalleRemision ?  
                                <ModalDetalleFacturaRemision 
                                    handleCierreModalRemision={handleCierreModalRemision} 
                                    dblFactura={showdblFactura}    
                                    show={showDetalleRemision}                                           
                                    title={'Remisión'}
                                /> : null 
                            }     
                            { showDetallePedido ?  
                                <ModalDetalleFacturaPedido 
                                    handleCierreModalPedido={handleCierreModalPedido} 
                                    dblFactura={showdblFactura}
                                    show={showDetallePedido}                                           
                                    title={'Soporte de Pedidos y Remisiones'}
                                /> : null 
                            }      
                        </TableBody>
                                                          
                        <TableFooter className={classes.tableHead}>
                            <TableRow className={classes.tableHeadRow}>
                                <TableCell align='left'className={classes.tableHeadText} style={{color:'#FFF'}} colSpan={6}>Total Registros: {totalRegistros}</TableCell>                                
                                <TableCell className={classes.tableHeadText} style={{color:'#FFF', textAlign:'right'}}>Totales:</TableCell>
                                <TableCell className={classes.tableHeadText} style={{color:'#FFF', textAlign:'right'}}>{formatterPeso.format(granSubtotal)}</TableCell>                                
                                <TableCell className={classes.tableHeadText} style={{color:'#FFF', textAlign:'right'}}>{formatterPeso.format(granIva)}</TableCell>                                
                                <TableCell className={classes.tableHeadText} style={{color:'#FFF', textAlign:'right'}}>{formatterPeso.format(granTotal)}</TableCell>
                                <TableCell align='left'className={classes.tableHeadText} style={{color:'#FFF'}} colSpan={2}>&nbsp;</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>                    
                </Paper>
                <div>                        
                    <button
                        id="btnDescargaMasiva"
                        type="submit"                
                        className={`btn btn-danger btn-elevate kt-login__btn-primary `}
                        style={{borderRadius: '20px',display: 'flex',align: 'center', margin:'10px'}}
                        onClick={() => checkboxActivos()}
                    >
                        <img
                            alt="Remision"
                            src={toAbsoluteUrl("/media/iconos/small/download.png")}                            
                            style={{marginRight:'10px'}}                  
                        />
                        Descarga de Documentos
                    </button>                       
                </div>
            </div>
        );
    }
}