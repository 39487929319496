import React, {Component, useState} from 'react';
// import { Auth } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Storage } from 'aws-amplify';


class myApp extends Component {
    state = {fileUrl: ''}
    componentDidMount(){
        Storage.get('1500x1500.jpeg')
        .then(data=>{
            this.setState({
                fileUrl:data
            })
        })
        .catch(err => {
            console.log('Error al obtener imagen')
        })        
    }


    render() {
        return (

          <div className="App" style={{padding: '0px 0px 40px'}}>
            <header className="App-header">              
              <h1 className='App-title'>Welcome to react</h1>
            </header>
            <img src={this.state.fileUrl} />
          </div>
        );
    }
   
  
}

export default withAuthenticator(myApp,{includeGreetings: true});