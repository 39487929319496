import React, {Component} from 'react';
import { Auth } from 'aws-amplify';
import { toAbsoluteUrl } from "../../../_metronic";
import Tooltip from '@material-ui/core/Tooltip';

function obtenerFecha(){
    var getfecha = new Date();
    return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}

class FacturaXML extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],          
            isLoadedXML: false
        };
        this.downloadEmployeeData = this.downloadEmployeeData.bind(this);
	} 
 
   
    
    async downloadEmployeeData  () {

        let strEmail = null
        try{
            strEmail = await Auth.currentUserInfo();  
            strEmail  = strEmail.attributes.email;
            // console.log(strEmail);
        }catch(e){
            // console.log('Error');
        }
        let fechaAlta = obtenerFecha();

        // store Procedure => sp_getFacturaXML
        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/facturacion/facturaxml';        
        var data = {
            "strEmail": strEmail,      
            "datFechaAlta": fechaAlta,
            "dblFactura": this.props.dblFactura            
        };

        console.log(data)

        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            // console.log(json);
            this.setState({
                isLoadedXML: true,
                items: json[0][0],  //Factura XML                                                
            });
            this.guardarXML();
        });        
		        
    }  
    
    guardarXML = () => {                   
        const element = document.createElement("a");
        const file = new Blob([this.state.items.strXMLFactura], {type: 'text/xml'});
        element.href = URL.createObjectURL(file);
        element.download = this.props.strFactura+".xml";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
  
    render() {  
    
        return(
            
            <Tooltip title="Descarga XML">
                <button
                    id="btnFacturaXML"
                    type="button"                
                    className={`btn btn-outline-success btn-elevate kt-login__btn-primary `}    
                    // style={{borderRadius: '20px',align: 'center', marginRight: '20px'}} 
                    style={{borderRadius: '20px',align: 'center', marginRight: '5px', padding:'5px'}} 
                    onClick={this.downloadEmployeeData}
                >
                    <img
                        alt="pdf"
                        src={toAbsoluteUrl("/media/iconos/small/xml.png")}
                        // width="40%"                    
                    />
                    {/* XML */}
                </button>
            </Tooltip>

        )          
    }
}

export default FacturaXML;
