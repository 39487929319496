import React, {useEffect, useState}from 'react'
import { Auth } from 'aws-amplify'
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { toAbsoluteUrl } from "../../../_metronic";
import Tooltip from '@material-ui/core/Tooltip';

// CARD
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';

// Loading
import CircularProgress from '@material-ui/core/CircularProgress';
import { v4 as uuidv4 } from 'uuid';

import {
    Button,
    Modal,
} from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),         
        color: "#f14258"
    },
    root: {
        width: '100%',
    },
    paper: {      
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },    
    table: {
        minWidth: 650,
    },
    tableRowRemision:{
        background:'#ffe4a9 !important'
    },    
    tableRowPedido:{
        background:'#9a99994d !important'
    },
    leyenda :{
        width:'15px',
        height:'15px',        
        display:'inline-block',
        borderRadius:'20px', 
        marginRight:'5px'
    },
    cardHeader:{
        padding:'5px',
        background:'#dc3546',
        '& > div span': {
            color: '#fff'
        },
        '& > div :first-child': {
            fontWeight: 'bold'
        },
    },
    avatarPedido: {
        backgroundColor: "#a90000",
        color:'#FFF'
    },
    avatarRemision: {
        backgroundColor: "#62f436",
        marginRight:'5px'
    },
    contentInfo:{
        fontWeight:'bold',
        fontSize: 13
    },
    cardContent:{
        textAlign:'center', 
        background:'#cacaca',
        padding:'5px'
    },
    cardActionsContainer:{
        lineHeight:'40px'
    },
    allCard:{
        marginTop:'10px'
    },
}));

const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
}))(Tooltip);

const formatterPeso = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
});

function obtenerFecha(){
    var getfecha = new Date();
    return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}

export function ModalDetalleFacturaPedido (props) {          
    const classes = useStyles();
    var arrArchivoRemisiones = [];
    
    // Carga de Datos
    let [state, setState] = useState({
        items: [],       
        isLoaded: false
    })

    function descargarRemisionPDF(nom){
        window.open('https://s3.us-east-2.amazonaws.com/compre-remisiones/'+nom);
    }

    function descargarComprobante(){
        state.items.map((data) => {
            const nombreArchivo = data.dblRemisiones + data.strArchivoComprobante;
            const extensionArchivo = data.strArchivoComprobante.substr(data.strArchivoComprobante.length - 3);
            arrArchivoRemisiones.push(
                {
                    "type": extensionArchivo,
                    "fileName": nombreArchivo,
                    "key": nombreArchivo
                }
            );
        });

        let nombreZip = uuidv4()+'.zip';

        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/facturacion/pedidos/descargarsoporte';
        let dataEnvio =
        {
            "nombreZip": nombreZip,
            "archivos": arrArchivoRemisiones
        };

        console.log(dataEnvio);

        
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(dataEnvio), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            console.log(json);
            /*          
            setState({
                loadPDf: false                  
            });*/
           window.open("https://compre-s3descargamasiva.s3.us-east-2.amazonaws.com/"+nombreZip, "Download");
        });
        //alert(newValue);

    }

    async function consultaDetalleFacturaPedido() {               

        let strEmail = null

        try{
            strEmail = await Auth.currentUserInfo();  
            strEmail  = strEmail.attributes.email;
        }catch(e){
            // console.log('Error get User');
        }

        let fechaAlta = obtenerFecha();

        // store Procedure => sp_getPedidoFactura
        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/facturacion/pedidos';        
        var data = {
            "strEmail": strEmail,      
            "datFechaAlta": fechaAlta,
            "dblFactura": props.dblFactura            
        };        
        

        console.log(data);
        // console.log(data);
        
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            console.log(json);
            setState({...state,
                isLoaded: true,
                items: json[0],  //Factura Detalle                
            });
        });
    }

    useEffect(() => {        
        consultaDetalleFacturaPedido();
    }, []) 

    var { isLoaded, items} = state

    // Indica el acomodo de las filas en la tabla
    var dblPedidoAnterior = "";

    // Suma de Totales de Pedido 
    var granTotalPedido = 0;

    return (                                         
        <Modal
            show={props.show}                        
            onHide = {()=> props.handleCierreModalPedido()}                                                  
            dialogClassName="modal-90w"            
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>      
                {
                    isLoaded ? 
                        <>
    
                        <div className={classes.root}>                    
                            <Paper className={classes.paper}>

                                {items.map((row, i) => (
                                    
                                    <div key={'contenido-'+i}>
                                        <Grid container>
                                            <Grid item xs={12} className={classes.allCard} style={dblPedidoAnterior == row.dblPedido ? {marginTop:'0px'} : {}}>    
                                                <Card className={classes.card}>  
                                                {
                                                    // Primer Elemento a analizar
                                                    i==0 ?
                                                        // Cuenta con Remisiones
                                                        row.dblRemisiones != null ?                                                                                                                                                                                                                
                                                            <>
                                                                <CardHeader
                                                                    avatar={
                                                                    <Avatar aria-label="Recipe" className={classes.avatarPedido}>
                                                                        P
                                                                    </Avatar>
                                                                    }                                                            
                                                                    title={
                                                                        //"Pedido " + row.strPedido
                                                                        <Grid container>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Pedido " + row.strPedido}
                                                                            </Grid>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Fecha: "+row.datFechaPedido}
                                                                            </Grid>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Orden de Compra: "+row.strPedidoCliente}
                                                                            </Grid>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Monto: "+formatterPeso.format(row.dblMontoPedido)} 
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                    //subheader={row.strPedido}
                                                                    content={                                                                
                                                                            dblPedidoAnterior = row.dblPedido,
                                                                            granTotalPedido += row.dblMontoPedido                                                                
                                                                    }    
                                                                    className={classes.cardHeader}                                                                                                                                                   
                                                                />
                                                                <CardActions className={classes.actions} >
                                                                    <Grid container className={classes.cardActionsContainer}>  
                                                                        
                                                                        <Grid item xs={1} className={classes.contentInfo}>               
                                                                            Remisión:
                                                                        </Grid>
                                                                        <Grid item xs={1}>               
                                                                            {row.strRemisiones}
                                                                        </Grid>
                                                                        <Grid item xs={1} className={classes.contentInfo}>               
                                                                            Despachó:
                                                                        </Grid>
                                                                        <Grid item xs={3}>               
                                                                            {row.strNombreCompleto}
                                                                        </Grid>
                                                                        <Grid item xs={1} className={classes.contentInfo}>
                                                                            Fecha: 
                                                                        </Grid>
                                                                        <Grid item xs={2}>           
                                                                            {
                                                                                ('0' + new Date(row.datFechaRemision).getDate()).slice(-2) + 
                                                                                '/' + ('0' + (new Date(row.datFechaRemision).getMonth()+1)).slice(-2) + 
                                                                                '/' + new Date(row.datFechaRemision).getFullYear() 
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={2}>           
                                                                            {
                                                                                row.strArchivoComprobante != null ?
                                                                                    <button
                                                                                        id="btnDescarga"
                                                                                        type="button"                
                                                                                        className={`btn btn-outline-success btn-sm `}  
                                                                                        style={{padding:'2px', borderRadius:'15px'}}
                                                                                        onClick={() => descargarRemisionPDF(row.dblRemisiones+row.strArchivoComprobante)}
                                                                                    >
                                                                                        <img
                                                                                            alt="detalle"
                                                                                            src={toAbsoluteUrl("/media/iconos/small/download.png")}
                                                                                        />
                                                                                        Ver comprobante
                                                                                    </button>
                                                                                : 'Sin Comprobante'

                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardActions>
                                                            </>                                                        
                                                        // Sin Remisiones
                                                        : 
                                                            <>   
                                                                {
                                                                    dblPedidoAnterior = row.dblPedido
                                                                    // granTotalPedido += row.dblMontoPedido
                                                                }
                                                                <CardHeader
                                                                    avatar={
                                                                    <Avatar aria-label="Recipe" className={classes.avatarPedido}>
                                                                        P
                                                                    </Avatar>
                                                                    }                                                            
                                                                    title={
                                                                        //"Pedido " + row.strPedido
                                                                        <Grid container>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Pedido " + row.strPedido}
                                                                            </Grid>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Fecha: "+row.datFechaPedido}
                                                                            </Grid>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Orden de Compra: "+row.strPedidoCliente}
                                                                            </Grid>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Monto: "+formatterPeso.format(row.dblMontoPedido)} 
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                    //subheader={row.strPedido}
                                                                    content={                                                                
                                                                            dblPedidoAnterior = row.dblPedido,
                                                                            granTotalPedido += row.dblMontoPedido                                                                
                                                                    }    
                                                                    className={classes.cardHeader}                                                                                                                                                   
                                                                />
                                                                <CardContent className={classes.cardContent}>
                                                                    <Grid container>            
                                                                        <Grid item xs={2} className={classes.contentInfo}>
                                                                            Fecha:  
                                                                        </Grid>
                                                                        <Grid item xs={2}>               
                                                                            {row.datFechaPedido}       
                                                                        </Grid>
                                                                        <Grid item xs={2} className={classes.contentInfo}>
                                                                            Ord. Compra: 
                                                                        </Grid>
                                                                        <Grid item xs={2}>           
                                                                            {row.strPedidoCliente} 
                                                                        </Grid>
                                                                        <Grid item xs={2} className={classes.contentInfo}>
                                                                            Monto:
                                                                        </Grid>
                                                                        <Grid item xs={2}>           
                                                                            {formatterPeso.format(row.dblMontoPedido)}
                                                                        </Grid>
                                                                    </Grid>            
                                                                </CardContent>
                                                                
                                                            </>
                                                    // demas elementos analizar
                                                    :
                                                        dblPedidoAnterior == row.dblPedido ?
                                                            <>                                                        
                                                                <CardActions className={classes.actions}>
                                                                    <div style={{display:'none'}}>
                                                                    {
                                                                        dblPedidoAnterior = row.dblPedido                                                          
                                                                    }
                                                                    </div>
                                                                    <Grid container className={classes.cardActionsContainer} style={{marginLeft:'0px'}}>  
                                                                        
                                                                        <Grid item xs={1} className={classes.contentInfo}>               
                                                                            Remisión:
                                                                        </Grid>
                                                                        <Grid item xs={1}>               
                                                                            {row.strRemisiones}
                                                                        </Grid>
                                                                        <Grid item xs={1} className={classes.contentInfo}>               
                                                                            Despachó:
                                                                        </Grid>
                                                                        <Grid item xs={3}>               
                                                                            {row.strNombreCompleto}
                                                                        </Grid>
                                                                        <Grid item xs={1} className={classes.contentInfo}>
                                                                            Fecha: 
                                                                        </Grid>
                                                                        <Grid item xs={2}>           
                                                                            {
                                                                                ('0' + new Date(row.datFechaRemision).getDate()).slice(-2) + 
                                                                                '/' + ('0' + (new Date(row.datFechaRemision).getMonth()+1)).slice(-2) + 
                                                                                '/' + new Date(row.datFechaRemision).getFullYear() 
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={2}>        
                                                                            {
                                                                                row.strArchivoComprobante != null ?
                                                                                    <button
                                                                                        id="btnDescarga"
                                                                                        type="button"                
                                                                                        className={`btn btn-outline-success btn-sm `}  
                                                                                        style={{padding:'2px', borderRadius:'15px'}}
                                                                                        onClick={() => descargarRemisionPDF(row.dblRemisiones+row.strArchivoComprobante)}
                                                                                    >
                                                                                        <img
                                                                                            alt="detalle"
                                                                                            src={toAbsoluteUrl("/media/iconos/small/download.png")}
                                                                                        />
                                                                                        Ver comprobante
                                                                                    </button>
                                                                                : 'Sin Comprobante'

                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardActions>
                                                            </>                                                
                                                        :
                                                            <>                                                       
                                                                <CardHeader
                                                                    avatar={
                                                                    <Avatar aria-label="Recipe" className={classes.avatarPedido}>
                                                                        P
                                                                    </Avatar>
                                                                    }                                                            
                                                                    title={
                                                                        //"Pedido " + row.strPedido
                                                                        <Grid container>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Pedido " + row.strPedido}
                                                                            </Grid>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Fecha: "+row.datFechaPedido}
                                                                            </Grid>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Orden de Compra: "+row.strPedidoCliente}
                                                                            </Grid>
                                                                            <Grid item xs={3} className={classes.contentInfo}>
                                                                                {"Monto: "+formatterPeso.format(row.dblMontoPedido)} 
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                    //subheader={row.strPedido}
                                                                    content={                                                                
                                                                            dblPedidoAnterior = row.dblPedido,
                                                                            granTotalPedido += row.dblMontoPedido                                                                
                                                                    }    
                                                                    className={classes.cardHeader}                                                                                                                                                   
                                                                />
                                                                <CardContent className={classes.cardContent}>
                                                                    <Grid container>            
                                                                        <Grid item xs={2} className={classes.contentInfo}>
                                                                            Fecha:  
                                                                        </Grid>
                                                                        <Grid item xs={2}>               
                                                                            {row.datFechaPedido}       
                                                                        </Grid>
                                                                        <Grid item xs={2} className={classes.contentInfo}>
                                                                            Ord. Compra: 
                                                                        </Grid>
                                                                        <Grid item xs={2}>           
                                                                            {row.strPedidoCliente} 
                                                                        </Grid>
                                                                        <Grid item xs={2} className={classes.contentInfo}>
                                                                            Monto:
                                                                        </Grid>
                                                                        <Grid item xs={2}>           
                                                                            {formatterPeso.format(row.dblMontoPedido)}
                                                                        </Grid>
                                                                    </Grid>            
                                                                </CardContent>
                                                                <CardActions className={classes.actions} >
                                                                    <Grid container className={classes.cardActionsContainer}>  
                                                                        
                                                                        <Grid item xs={1} className={classes.contentInfo}>               
                                                                            Remisión:
                                                                        </Grid>
                                                                        <Grid item xs={1}>               
                                                                            {row.strRemisiones}
                                                                        </Grid>
                                                                        <Grid item xs={1} className={classes.contentInfo}>               
                                                                            Despachó:
                                                                        </Grid>
                                                                        <Grid item xs={3}>               
                                                                            {row.strNombreCompleto}
                                                                        </Grid>
                                                                        <Grid item xs={1} className={classes.contentInfo}>
                                                                            Fecha: 
                                                                        </Grid>
                                                                        <Grid item xs={2}>           
                                                                            {
                                                                                ('0' + new Date(row.datFechaRemision).getDate()).slice(-2) + 
                                                                                '/' + ('0' + (new Date(row.datFechaRemision).getMonth()+1)).slice(-2) + 
                                                                                '/' + new Date(row.datFechaRemision).getFullYear() 
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={2}>           
                                                                            {
                                                                                row.strArchivoComprobante != null ?
                                                                                    <button
                                                                                        id="btnDescarga"
                                                                                        type="button"                
                                                                                        className={`btn btn-outline-success btn-sm `}  
                                                                                        style={{padding:'2px', borderRadius:'15px'}}
                                                                                        onClick={() => descargarRemisionPDF(row.dblRemisiones+row.strArchivoComprobante)}
                                                                                    >
                                                                                        <img
                                                                                            alt="detalle"
                                                                                            src={toAbsoluteUrl("/media/iconos/small/download.png")}
                                                                                        />
                                                                                        Ver comprobante
                                                                                    </button>
                                                                                : 'Sin Comprobante'

                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardActions>
                                                                
                                                            </>
                                                }                                            
                                                </Card>
                                            </Grid>                                            
                                        </Grid>
                                    </div>                                       
                                    
                                    
                                ))}                                                               
                            </Paper>
                            
                            <Grid container spacing={3}>
                                <Grid item xs={12} style={{textAlign: 'right'}}>                                
                                    <div className={classes.leyenda} style={{background:'#dc3545'}}></div>
                                    <div style={{fontWeight:'bold', display:'inline-block', marginRight:'2px'}}>Total Pedido(s): </div>                                                                
                                    {formatterPeso.format(granTotalPedido)}
                                </Grid>
                                {/* <Grid item xs={12}>                                                             
                                    <div className={classes.leyenda} style={{background:'#ffe4a9'}}></div>
                                    Remisión                                
                                </Grid> */}
                            </Grid>
                                
                        </div>

                        </>

                    : 
                    <div className={"row justify-content-center"}>
                        <CircularProgress className={classes.progress} color="secondary" size={100}/>      
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={()=> descargarComprobante()}
                >
                    <img
                        alt="Remision"
                        src={toAbsoluteUrl("/media/iconos/small/download.png")}                            
                        style={{marginRight:'10px'}}                  
                    />
                    Descargar Soporte
                </Button>            
                <Button 
                    variant="danger" 
                    onClick={()=> props.handleCierreModalPedido()}
                >
                    Cerrar
                </Button>                
            </Modal.Footer>

        </Modal>
        
    )

}