import React, {useEffect, useState}from 'react'
import { Auth } from 'aws-amplify'
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

// Loading
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    Button,
    Modal,
} from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),         
        color: "#f14258"
    },
    root: {
        width: '100%',
    },
    paper: {      
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },    
    table: {
        minWidth: 650,
    },    
    rootDetalle:{
        flexGrow: 1,
    },
    paperDetalle:{
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const StyledTableHead = withStyles(theme => ({
    head: {
        backgroundColor: '#676666',
        color: '#fff',
    },    
}))(TableRow);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 11,
    },
}))(TableCell);

const formatterPeso = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
});

function obtenerFecha(){
    var getfecha = new Date();
    return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}


export function ModalDetalleFactura (props) {          
    const classes = useStyles();  
    
    // Carga de Datos
    let [state, setState] = useState({
        items: [],     
        itemsRemision : [],
        itemsPartida : [],
        isLoaded: false
    }) 

    async function consultaDetalleFactura() {               

        let strEmail = null

        try{
            strEmail = await Auth.currentUserInfo();  
            strEmail  = strEmail.attributes.email;
        }catch(e){
            // console.log('Error get User');
        }

        let fechaAlta = obtenerFecha();

        //store Procedure => sp_getFacturaDetalle
        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/facturacion/facturadetalle';        
        var data = {
            "strEmail": strEmail,      
            "datFechaAlta": fechaAlta,
            "dblFactura": props.dblFactura,            
        };

        console.log(data);
        
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            // console.log(json);
            setState({...state,
                isLoaded: true,
                items: json[0][0],  //Factura Detalle                
                itemsRemision : json[1],  //Factura Detalle Remision
                itemsPartida : json[2],  //Factura Detalle Remision
            });
        });



    }

    useEffect(() => {        
        consultaDetalleFactura();
    }, []) 

    var { isLoaded, items, itemsRemision, itemsPartida} = state

     // Suma de Totales de Pedido Clientes

    let granCantidad = itemsPartida.reduce(function(prev, current) {
        return prev + +((current.Cantidad).toFixed(2))        
    }, 0);

    let granSubtotal = itemsPartida.reduce(function(prev, current) {
        return prev + +((current.SubTotal).toFixed(2))        
    }, 0);

    let granIva = itemsPartida.reduce(function(prev, current) {
        return prev + +((current.IVA).toFixed(2))   
    }, 0);
    
    let granTotal = itemsPartida.reduce(function(prev, current) {
        return prev + +((current.Total).toFixed(2)) 
    }, 0);           
    
    return (                             
            
        <Modal
            show={props.show}                        
            onHide = {()=> props.handleCierreModal()}                                                  
            dialogClassName="modal-90w"            
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>      

                {isLoaded ? 
                    <>
                        <div className={classes.rootDetalle}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Paper className={classes.paperDetalle} style={{color:'#fff', backgroundColor:'#676666', fontWeight:'bold'}}>
                                        Factura
                                    </Paper>
                                    <Paper className={classes.paperDetalle}>
                                        {items.strSerie}{items.strFactura}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3}>
                                    <Paper className={classes.paperDetalle} style={{color:'#fff', backgroundColor:'#676666', fontWeight:'bold'}}>
                                        Fecha Factura
                                    </Paper>
                                    <Paper className={classes.paperDetalle}>                                
                                        {
                                            ('0' + new Date(items.datFechaFactura).getDate()).slice(-2) + 
                                            '/' + ('0' + (new Date(items.datFechaFactura).getMonth()+1)).slice(-2) + 
                                            '/' + new Date(items.datFechaFactura).getFullYear() 
                                        }
                                    </Paper>
                                </Grid>
                                <Grid item xs={3}>
                                    <Paper className={classes.paperDetalle} style={{color:'#fff', backgroundColor:'#676666', fontWeight:'bold'}}>
                                        Condiciones Pago
                                    </Paper>
                                    <Paper className={classes.paperDetalle}>
                                        {items.strCondicionesPago}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3}>
                                    <Paper className={classes.paperDetalle} style={{color:'#fff', backgroundColor:'#676666', fontWeight:'bold'}}>
                                        Moneda
                                    </Paper>
                                    <Paper className={classes.paperDetalle}>
                                        {items.strMoneda}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3}>
                                    <Paper className={classes.paperDetalle} style={{color:'#fff', backgroundColor:'#676666', fontWeight:'bold'}}>
                                        Vendedor
                                    </Paper>
                                    <Paper className={classes.paperDetalle}>
                                        {items.strUsuarioVendedor}
                                    </Paper>
                                </Grid>

                                {itemsRemision.length != 0  ? 
                                    <Grid item xs={3}>
                                        <Paper className={classes.paperDetalle} style={{color:'#fff', backgroundColor:'#676666', fontWeight:'bold'}}>
                                            Pedido
                                        </Paper>
                                        <Paper className={classes.paperDetalle}>
                                            {itemsRemision[0].strPedido}
                                        </Paper>
                                    </Grid>                        
                                :null}                        
                                {itemsRemision.length != 0 ? 
                                    <Grid item xs={3}>
                                        <Paper className={classes.paperDetalle} style={{color:'#fff', backgroundColor:'#676666', fontWeight:'bold'}}>
                                            Tipo Vivivenda
                                        </Paper>
                                        <Paper className={classes.paperDetalle}>
                                            {itemsRemision[0].strTipoVivienda}
                                        </Paper>
                                    </Grid>
                                :null}
                                {itemsRemision.length != 0 ? 
                                    <Grid item xs={3}>
                                        <Paper className={classes.paperDetalle} style={{color:'#fff', backgroundColor:'#676666', fontWeight:'bold'}}>
                                            Obra
                                        </Paper>
                                        <Paper className={classes.paperDetalle}>
                                            {itemsRemision[0].strObra}
                                        </Paper>
                                    </Grid>                         
                                :null}
                                {itemsRemision.length != 0 ?                             
                                    <Grid item xs={6} >
                                        <Paper className={classes.paperDetalle} style={{color:'#fff', backgroundColor:'#676666', fontWeight:'bold'}}>
                                            Dirección
                                        </Paper>
                                        <Paper className={classes.paperDetalle}>
                                            {itemsRemision[0].strDireccionEntrega}
                                        </Paper>
                                    </Grid>                                                              
                                :null}
                            </Grid>
                        </div>     


                        <div className={classes.root} style={{marginTop:'10px'}}>
                            <h3>Partida de la Factura</h3>
                            <Paper className={classes.paper}>
                                <Table className={classes.table} size="small">
                                    <TableHead className={'text-center'}>
                                        <StyledTableHead>                                    
                                            <th align="center">Producto / Servicio</th>
                                            <th align="center">Cantidad</th>
                                            <th style={{textAlign:'right'}}>Precio Unitario</th>                                    
                                            <th style={{textAlign:'right'}}>Descuento</th>
                                            <th style={{textAlign:'right'}}>Subtotal</th>
                                            <th style={{textAlign:'right'}}>IVA</th>
                                            <th style={{textAlign:'right'}}>Total</th>                                    
                                        </StyledTableHead>
                                    </TableHead>                
                                    <TableBody>       
                                        {itemsPartida.map( (row, i) => (      
                                            <StyledTableRow key={'detalle-'+i}>
                                                    <StyledTableCell align="center">{row.Producto}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.Cantidad}</StyledTableCell>
                                                    <StyledTableCell style={{textAlign:'right', paddingRight:'5px'}}>{                                                                                                
                                                        formatterPeso.format(row.Precio)   
                                                    }</StyledTableCell>
                                                    <StyledTableCell style={{textAlign:'right', paddingRight:'5px'}}>{                                                  
                                                        formatterPeso.format(row.dblDescuento)   
                                                    }</StyledTableCell>
                                                    <StyledTableCell style={{textAlign:'right', paddingRight:'5px'}}>{                                                  
                                                        formatterPeso.format(row.SubTotal)   
                                                    }</StyledTableCell>
                                                    <StyledTableCell style={{textAlign:'right', paddingRight:'5px'}}>{                                                  
                                                        formatterPeso.format(row.IVA)   
                                                    }</StyledTableCell>
                                                    <StyledTableCell style={{textAlign:'right', paddingRight:'5px'}}>{                                                                                                
                                                        formatterPeso.format(row.Total) 
                                                    }</StyledTableCell>                
                                            </StyledTableRow>                      
                                        ))}
                                    </TableBody>
                                    <TableHead>
                                        <StyledTableHead >
                                            <th className={'text-right'}>Totales:</th>
                                            <th className={'text-center'} >{granCantidad}</th>                                    
                                            <th className={'text-left'} colSpan="2" >&nbsp;</th>        
                                            <th className={'text-right'} style={{ paddingRight:'5px'}}>{formatterPeso.format(granSubtotal)}</th>
                                            <th className={'text-right'} style={{ paddingRight:'5px'}}>{formatterPeso.format(granIva)}</th>
                                            <th className={'text-right'} style={{ paddingRight:'5px'}}>{formatterPeso.format(granTotal)}</th>                                                                                                            
                                        </StyledTableHead>
                                    </TableHead>
                                </Table>
                            </Paper>
                        </div>

                    </>

                : 
                <div className={"row justify-content-center"}>
                    <CircularProgress className={classes.progress} color="secondary" size={100}/>      
                </div>
                }

                

                
            
            </Modal.Body>
            <Modal.Footer>                       
                <Button variant="danger" onClick={()=> props.handleCierreModal()}>Cerrar</Button>                
            </Modal.Footer>

        </Modal>
        
    )
    

}