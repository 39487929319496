import React, { useState, useEffect, useRef, Fragment } from "react";
import { Auth } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Checkbox from '@material-ui/core/Checkbox';
import {  
  ButtonGroup,  
} from "react-bootstrap";
import { Form } from "react-bootstrap";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toAbsoluteUrl } from "../../../_metronic";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, useHistory, Redirect } from "react-router-dom";
// Filtro
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

// Archivos Externos
import ComplementoPagoPDF from "./ProComplementoPagoPDF";
import ComplementoPagoXML from "./ProComplementoPagoXML";

import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),         
        color: "#f14258"
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    filtro: {
        padding: theme.spacing(1, 1),
        marginBottom: theme.spacing(1),      
    },
    table: {
        minWidth: 650,
        border:'5px solid #d41f36',
        borderRadius:'10px',
        padding:'0px'
    },
    tableHead:{
        backgroundColor: '#d41f36',
        color: '#fff',
        textAlign: 'center',
        margin:'0px'
    },
    tableRowBody:{
        '&:nth-of-type(odd)': {
            backgroundColor: '#f1f1f1',
        },
    },
    tableDatos:{
        borderBottom:'0px',
        padding:'0px'
    },
    
}));


const formatterPeso = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
});

function obtenerFecha(){
    var getfecha = new Date();
    return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}
                  
export default function Pago() {

    let refValue = useRef();
    let refValue2 = useRef(); 

    var fecha = new Date();
    var fechaInicioMes = fecha.getFullYear()+'-'+('0' + (fecha.getMonth()+1)).slice(-2)+'-01';  
    var fechaActual = fecha.getFullYear()+'-'+('0' + (fecha.getMonth()+1)).slice(-2)+'-'+('0' + (fecha.getDate())).slice(-2);

    const classes = useStyles(); 
    let history = useHistory();

    // const [showdblCobranza, setdblCobranza] = useState();

    // init:Carga de Datos
    let [state, setState] = useState({
        items: [],                                    
        isLoaded: false          
    }) 

    const [showFechaInit, setFechaInit] = useState(false);
    const [showFechaFin, setFechaFin] = useState(false);
    const [showPDF, setPDF] = useState(false);

    
    // Config checkbox
    const newValue = [];
    const [checkedItems, setCheckedItems] = React.useState({});       

    function customSetFiltrarPago () {       
        setState({isLoaded: false})
        setFechaInit(refValue.current.value);
        setFechaFin(refValue2.current.value);
        consultaPagos(refValue.current.value,refValue2.current.value)
    } 

    async function consultaPagos(fechaInicial, fechaFinal) { 
        var fechaPedidoInicial = "";
        var fechaPedidoFinal = "";     
        if (fechaInicial === undefined || fechaInicial === ""){
            fechaPedidoInicial = fecha.getFullYear()+'-'+('0' + (fecha.getMonth()+1)).slice(-2)+'-01 00:00:00';      
        } else {
            fechaPedidoInicial = fechaInicial +" 00:00:00";
        }
        if (fechaFinal === undefined || fechaFinal === ""){
            fechaPedidoFinal = fechaActual +" 23:59:59";
        } else {
            fechaPedidoFinal = fechaFinal+" 23:59:59";
        }

        // console.log('fechaPedidoInicial: '+ fechaPedidoInicial + ' fechaPedidoFinal: '+ fechaPedidoFinal);    
                        
        let strEmail = null;

        if (localStorage.getItem('usuario') != null){
            strEmail = localStorage.getItem('usuario');                   
        }else{
            console.log("No hay usuario almacenado en el localstorage");
            history.push({
                pathname: '/logout',               
            }); 
        }
               
        let fechaAlta = obtenerFecha();
        // Store Procedure => sp_getPedidos
        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/clientes/pagos';            
        var data = {
            "strEmail": strEmail,  
            "datFechaPagoInicio" : fechaPedidoInicial,
            "datFechaPagoFin" : fechaPedidoFinal,
            "datFechaAlta": fechaAlta                
        };  
        
        console.log(data);
                
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            console.log(json);
            
            setState({...state,
                isLoaded: true,
                items: json[0]                
            });
        });

    }

    useEffect(() => {  
        verificarUser();              
        consultaPagos();
    }, []) 

    async function verificarUser(){
        let response = null;
        try{
            response = await Auth.currentUserInfo(); 
            if(JSON.stringify(response) == JSON.stringify({}) ){
                console.log('Usuario no existe');
                history.push({
                    pathname: '/logout',               
                }); 
            }                     
        }catch(e){
            console.log('Error get user')
            history.push({
                pathname: '/logout',               
            });                             
        }
    }

    function checkboxActivos(){
        // Validación        
        if(newValue.length === 0){
            alert("Seleccioné al menos una casilla para proceder con la descarga de documentos");
            return false;
        } 
        //función para descargar el .zip con los xml y pdf de las facturas.
        let nombreZip = uuidv4()+'.zip';
        console.log('nombre de zip -> '+nombreZip);

        var arrObjArchivos = [];

        newValue.map((data) => {
            /* se deshabilita porque no hay archivos en pdf
            arrObjArchivos.push(
                {
                    "type": "pdf",
                    "fileName": data+".pdf",
                    "key": data+".pdf",
                }
            );
            */
            arrObjArchivos.push(
                {  
                    "type": "xml",
                    "fileName": data+".xml",
                    "key": data+".xml"
                }
            );
        });

        var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/clientes/pagos/descargamasiva';
        let dataEnvio =
        {
            "nombreZip": nombreZip,
            "archivos": arrObjArchivos
        };
        
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(dataEnvio), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
           console.log(json);
           window.open("https://compre-s3descargamasiva.s3.us-east-2.amazonaws.com/"+nombreZip, "Download");
        });
        //alert(newValue);
    }

    var { isLoaded, items} = state
    var granMontoTotal = 0;
    var totalRegistros = 0;  
        
    
    if (!isLoaded){
        return(      
            <div className={"row justify-content-center"}>
                <CircularProgress className={classes.progress} color="secondary" size={100}/>      
            </div>
        )
    } else { 
        return (
            <div>
                <Paper className={classes.filtro}>
                    <Typography variant="h5" component="h3">          
                        <form className={classes.container} noValidate>
                            <div >
                            <Button variant="contained" size="large" style={{ backgroundColor: '#e6e5eb' }} onClick={() => customSetFiltrarPago()}>
                                <SearchIcon style={{ fontSize: 28}}/>
                            </Button>              
                            </div>
                        
                            <TextField                
                                inputRef={refValue}
                                id="fechaPagoInicio"
                                label="Fecha Pago Inicial"
                                type="date"                                                            
                                defaultValue={(showFechaInit ? showFechaInit: fechaInicioMes)}
                                className={classes.textField}
                                style = {{width: '42%'}}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                            <TextField
                                inputRef={refValue2}
                                id="fechaPedidoFin"
                                label="Fecha Pago Final"
                                type="date"                            
                                defaultValue={(showFechaFin ? showFechaFin: fechaActual)}                            
                                className={classes.textField}
                                style = {{width: '42%'}}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                        </form>                              
                    </Typography>
                </Paper>


                <Paper className={classes.paper} style={{marginBottom:'5px'}}>
                    <Table className={classes.table} size="small">                    
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell align='center' style={{color:'#FFF'}}>
                                    <Form.Group controlId="formBasicChecbox" style={{padding:'0px', margin:'0px'}}>
                                        <Form.Check 
                                            type="checkbox"                                         
                                            onChange={(event) => {                                                                           
                                                newValue.splice(0,newValue.length);
                                                {items.map((item, i) => {                                                         
                                                    if (event.target.checked) {                                                                                                    
                                                        if(!document.getElementById('chechbox-'+item.dblCobranza).hasAttribute("disabled")){
                                                            document.getElementById('chechbox-'+item.dblCobranza).checked = true;  
                                                            newValue.push(item.strComplementoPagoS3);
                                                        }                                                                                                            
                                                    } else {                                                
                                                        document.getElementById('chechbox-'+item.dblCobranza).checked = false;                                                        
                                                        newValue.splice(newValue.indexOf(item.strComplementoPagoS3), 1);
                                                    }
                                                })};                                                       
                                                console.log(newValue);                                                                                                
                                            }}
                                        />
                                    </Form.Group>
                                </TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Id Cobro</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Comp. pago</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>F. de Pago</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Factura</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>F. Factura</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Factoraje</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>M. Pago</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Estatus</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>M. Pagado</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Com.</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>M. Total</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Descargas</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, i) => {                               
                                granMontoTotal += item.montoCompensacion + item.dblMonto;
                                totalRegistros += 1;
                                return (
                                    <Fragment key={'prueba-'+i}>                                         
                                        <TableRow key={'datos-'+i} className={classes.tableRowBody}>
                                            <TableCell align="center" className={classes.tableDatos}>
                                                <Form.Group controlId="formBasicChecbox" style={{margin:'0px'}}>
                                                    <Form.Check 
                                                        type="checkbox"    
                                                        id={`chechbox-${item.dblCobranza}`}                                                 
                                                        name={`chechbox-${item.dblCobranza}`}                                                   
                                                        onChange={(event) => {
                                                            console.log(event.target.checked);
                                                            if (event.target.checked) {
                                                                newValue.push(item.strComplementoPagoS3);
                                                            } else {
                                                                newValue.splice(newValue.indexOf(item.strComplementoPagoS3), 1);
                                                            }
                                                            console.log(newValue);
                                                        }}
                                                        value={item.dblCobranza}   
                                                        disabled={item.strXMLCobranza == null  ? 'disabled': null}    
                                                                                                     
                                                    />
                                                </Form.Group>                                                
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>{item.dblCobranza}</TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>
                                                { 
                                                    item.dblCobranzaTimbradoSAT != null ? 
                                                        item.datFechaCancelacionAlta === 0 && item.strXMLCobranza == null ?
                                                            item.strSerieComplementoPago == null?
                                                                'Por Asignar'
                                                            :item.strSerieComplementoPago
                                                        : item.strSerieComplementoPago
                                                    : item.strSerieComplementoPago                                           
                                                }
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>{item.datFechaPago}</TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>{item.strSerie+item.strFactura}</TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>{item.datFechaFactura}</TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>
                                                {
                                                        item.idProvCompensacion != null ?
                                                            item.idProvCompensacion+'-'+item.nombreProvCompensacion 
                                                        : '-'                                                                                    
                                                }
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>{item.strCodigoMetodoPagoSat}</TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>{item.strEstatusFactura}</TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>
                                                {                                                                                     
                                                    formatterPeso.format(item.dblMonto)   
                                                }
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>
                                                {                                           
                                                    item.montoCompensacion !== 0 ?                                                                                                        
                                                        formatterPeso.format(item.montoCompensacion)  
                                                        
                                                    : '-' 
                                                }
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableDatos}>
                                                {
                                                    item.montoCompensacion !== 0 ?                                                                                                        
                                                        formatterPeso.format(item.montoCompensacion + item.dblMonto)  
                                                    :                                                     
                                                        formatterPeso.format(item.dblMonto)  
                                                }
                                            </TableCell>     
                                            <TableCell align="center" className={classes.tableDatos}>
                                                <div className="flex-column" style={{display:'grid', justifyContent:'center'}}>
                                                    <ButtonGroup size="sm">
                                                        { item.strXMLCobranza ? 
                                                            <ComplementoPagoPDF 
                                                                dblCobranza={item.dblCobranza}
                                                                dblCobranzaTimbradoSAT = {item.dblCobranzaTimbradoSAT}
                                                                handleClick={showPDF}      
                                                                strSerieComplementoPago={item.strSerieComplementoPago}                                                   
                                                            /> 
                                                            : null
                                                        }
                                                        
                                                        { item.strXMLCobranza ? 
                                                            <ComplementoPagoXML 
                                                                dblCobranza={item.dblCobranza}
                                                                dblCobranzaTimbradoSAT = {item.dblCobranzaTimbradoSAT}                                             
                                                                strSerieComplementoPago={item.strSerieComplementoPago}                                                   
                                                            /> 
                                                            : null
                                                        }
                                                    </ButtonGroup>
                                                </div>
                                            </TableCell>    
                                        </TableRow>                                                                                                                                                                                        
                                    </Fragment>
                                );
                            })} 
                        </TableBody>
                        <TableFooter className={classes.tableHead}>
                            <TableRow className={classes.tableHeadRow}>
                                <TableCell align='left'className={classes.tableHeadText} style={{color:'#FFF'}} colSpan={9}>Total Registros: {totalRegistros}</TableCell>                                
                                <TableCell className={classes.tableHeadText} style={{color:'#FFF', textAlign:'right'}} colSpan={2}>Monto Total:</TableCell>
                                <TableCell className={classes.tableHeadText} style={{color:'#FFF', textAlign:'right'}}>{formatterPeso.format(granMontoTotal)}</TableCell>                                                                                                
                                <TableCell align='left'className={classes.tableHeadText} style={{color:'#FFF'}} colSpan={1}>&nbsp;</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Paper>                
                <div>                        
                    <button
                        id="btnDescargaMasiva"
                        type="submit"                
                        className={`btn btn-danger btn-elevate kt-login__btn-primary `}
                        style={{borderRadius: '20px',display: 'flex',align: 'center', margin:'10px'}}
                        onClick={() => checkboxActivos()}
                    >
                        <img
                            alt="download"
                            src={toAbsoluteUrl("/media/iconos/small/download.png")}                    
                            style={{marginRight:'10px'}}                  
                        />
                        Descarga de Documentos
                    </button>                   
                </div>                                
          </div>                        
        )
    }
}