import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";

export default function AuthPage() {
  // console.log("authPage");
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg_login.jpg")})`
              }}
            >
              {/* <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo.png")}
                    width="40%"
                  />
                </Link>
              </div> */}
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">Compre Grupo - Portal de Clientes</h3>
                  <h4 className="kt-login__subtitle">                    
                    Le damos la bienvenida al portal de cliente de Compre, si aún no tiene su usuario y contraseña, favor de comunicarse con Atención a Clientes enviándonos un mensaje desde el botón de WhatsApp o al correo <a href="mailto:atencionaclientes@compre.com.mx">atencionaclientes@compre.com.mx</a>  
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; Compre Grupo Copyright © 2020
                  </div>
                  <div className="kt-login__menu">
                    {/* <Link to="/terms" className="kt-link">
                      Privacidad
                    </Link>
                    <Link to="/terms" className="kt-link">
                      Legal
                    </Link> */}
                    {/* <Link to="/terms" className="kt-link">
                      Contacto
                    </Link> */}

                    <a
                      href="https://api.whatsapp.com/send?phone=528129174748&text=Buen+d%C3%ADa%2C+los+contacto+desde+el+Portal+de+Clientes...++"
                      target="_blank"
                      className="kt-footer__menu-link kt-link" 
                      style={{ marginTop: "-10px" }}
                      >
                      <img src="/media/logos/whatsapp.png" width="35px"></img>
                    </a>
                    <a
                      href="https://www.compre.com.mx/contacto/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="kt-footer__menu-link kt-link">
                      Contacto
                    </a>
                  </div>
                </div>
              </div>
            </div>
              {/* aqui */}
            {/* <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"> */}
            <div
              className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
              style={{
                background: `#e0e0e0`
              }}
            >
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/registration" component={Registration} />
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
