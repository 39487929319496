import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";

class Footer extends React.Component {
  render() {
    const today = new Date().getFullYear();

    return (
      <div
        className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
        id="kt_footer"
      >
        <div className={`kt-container ${this.props.footerContainerClasses}`}>
          <div className="kt-footer__copyright">
            {today.toString()}&nbsp;&copy;&nbsp;
            <a
              href="https://www.compre.com.mx/"
              target="_blank"
              rel="noopener noreferrer"
              className="kt-link"
            >
              Grupo Compre Copyright
            </a>
          </div>          
          <div className="kt-footer__menu">
            {/* <a
              href="http://keenthemes.com/metronic"
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              Acerca de
            </a>
            <a
              href="http://keenthemes.com/metronic"
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              Equipo
            </a> */}
            
            <a
              href="https://api.whatsapp.com/send?phone=528129174748&text=Buen+d%C3%ADa%2C+los+contacto+desde+el+Portal+de+Clientes...++"
              target="_blank"
              className="kt-footer__menu-link kt-link"              
              style={{ marginTop: "-10px" }}
              >
              <img src="/media/logos/whatsapp.png" width="35px"></img>
            </a>

            <a
              href="https://www.compre.com.mx/contacto/"
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              Contacto
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true
  })
});

export default connect(mapStateToProps)(Footer);
