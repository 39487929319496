import React, {Component} from 'react';
import { toAbsoluteUrl } from "../../../_metronic";
import Tooltip from '@material-ui/core/Tooltip';

export default class FacturaPDF extends Component {

    constructor(props) {
        super(props);    
        this.state = {
            items: [],          
            isLoadedPDF: false
        };
    }  
 
    render() {
        return(
            <Tooltip title="Descarga PDF">
                <a
                    href = {'https://compre-pdf-factura.s3.us-east-2.amazonaws.com/'+this.props.strArchivoFactura+'.pdf'}
                    target = "_blank"
                    id="btnPDF"
                    className={`btn btn-outline-danger btn-elevate kt-login__btn-primary `}
                    // style={{borderRadius: '20px',align: 'center', marginRight: '20px'}}
                    style={{borderRadius: '20px',align: 'center', marginRight: '5px', padding:'5px'}} 
                    download={this.props.strArchivoFactura+'.pdf'}
                >
                    <img
                        alt="pdf"
                        src={toAbsoluteUrl("/media/iconos/small/pdf.png")}                    
                    />
                    {/* PDF */}
                </a> 
            </Tooltip>
        )          
    }
}

// export default myApp;
