/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Auth } from 'aws-amplify'
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// Iconos
import CircularProgress from '@material-ui/core/CircularProgress';

function obtenerFecha(){
  var getfecha = new Date();
  return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}

const formatterPeso = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 2
});


export default class StickyToolbar extends React.Component {

  state = {}

  constructor(props) {
      super(props);
  
      this.state = {
          items: [],
          isLoaded: false,
      };
  }

  async componentDidMount() {
      const response = await Auth.currentUserInfo()    
      var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/clientes/saldos';
      var data = {
          "strEmail": response.attributes.email,           
          "datFechaAlta": obtenerFecha(),      
      };

      console.log(data);
      
      fetch(url, {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers:{
              'Content-Type': 'application/json'
          }
      })
      .then(res => res.json())
      .then(json => {
        if(json[0][0] != "" || json[0][0] != undefined){
          this.setState({
              isLoaded: true,
              items: json[0][0],
          });
        }else{
          this.setState({
            isLoaded: true,
            items: {ATiempo:0,
                    dblMontoActual:0,
                    De1a30:0,
                    De31a60:0,
                    De61a90:0,
                    MayorA91:0
                  },
        });
        }
      });
  }

  
    
  
  render() {

    if(this.state.isLoaded){                 
        return (
          <>
            <ul className="kt-sticky-toolbar" style={{ marginTop: "30px" }}>
              <li                
                className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
                data-placement="left"
                style={{fontWeight:'bold'}}
              > 
                Saldos
              </li>
              { 
                  this.state.items ? 
                      <div>
                          <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip id="layout-tooltip"><b>A TIEMPO:</b> {formatterPeso.format(this.state.items.ATiempo - this.state.items.dblMontoActual)}</Tooltip>}
                          >
                            <li
                            
                              className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
                              data-placement="left"
                            >              
                                {/* <i className="flaticon2-gear" /> */}                
                                
                                <button className={'btn btn-success btn-sm'} style={{padding:'15%', borderRadius:'10px'}}>
                                  <AttachMoneyIcon />              
                                  
                                </button>
                            </li>            
                          </OverlayTrigger>
                        
                          <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip id="layout-tooltip"><b>TOTAL VENCIDO:</b> {
                              formatterPeso.format(this.state.items.De1a30 + this.state.items.De31a60 + this.state.items.De61a90 + this.state.items.MayorA91)
                              }</Tooltip>}
                          >                
                            <li
                              className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
                              data-placement="left"
                            >              
                                {/* <i className="flaticon2-gear" /> */}                
                                <button className={'btn btn-danger btn-sm'} style={{padding:'15%', borderRadius:'10px'}}>
                                  <AttachMoneyIcon />              
                                </button>
                            </li>            
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip id="layout-tooltip"><b>GRAN TOTAL:</b> {
                              formatterPeso.format(this.state.items.ATiempo + this.state.items.De1a30 + this.state.items.De31a60 + this.state.items.De61a90 + this.state.items.MayorA91 - this.state.items.dblMontoActual)
                            }</Tooltip>}
                          >
                            <li
                              className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
                              data-placement="left"
                            >              
                                {/* <i className="flaticon2-gear" /> */}                
                                <button className={'btn btn-success btn-sm'} style={{padding:'15%', borderRadius:'10px'}}>
                                  <AttachMoneyIcon />              
                                </button>
                            </li>            
                          </OverlayTrigger>

                      </div>
                  : null
              }
              
              
              
              {/* <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="documentations-tooltip">Documentation</Tooltip>}
              >
                <li
                  className="kt-sticky-toolbar__item kt-sticky-toolbar__item--warning"
                  data-placement="left"
                >
                  
                      <i className="flaticon2-telegram-logo" />                 
                </li>
              </OverlayTrigger> */}
            </ul>
          </>
        );
    } else {
      return(      
          null
      )
      
    }
  };
}
