import React, { useState, useEffect, useRef, Fragment } from "react";
import { Auth } from 'aws-amplify'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { toAbsoluteUrl } from "../../../_metronic";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import {  
  ButtonGroup,  
} from "react-bootstrap";

// Iconos
import CircularProgress from '@material-ui/core/CircularProgress';


// Filtro
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Modales
import {ModalDetalleFactura} from "../Components/modalDetalleFactura";
import {ModalDetalleFacturaRemision} from "../Components/modalDetalleFacturaRemision";
import {ModalDetalleFacturaPedido} from "../Components/modalDetalleFacturaPedido";
import FacturaPDF from "./ProFacturaPDF";
import FacturaXML from "./ProFacturaXML";

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),         
        color: "#f14258"
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    filtro: {
        padding: theme.spacing(1, 1),
        marginBottom: theme.spacing(1),      
    },
    table: {
        minWidth: 650,
        border:'5px solid #d41f36',
        borderRadius:'10px',
        padding:'0px'
    },
    tableHead:{
        backgroundColor: '#d41f36',
        color: '#fff',
        textAlign: 'center',
        margin:'0px'
    },
    tableDatos:{
        borderBottom:'0px'
    },
    
}));

const BtnPurple = withStyles(theme => ({
  root: {
    border: '1px solid black',
    borderColor: '#c01def',
    color: '#c01def',
    borderRadius: '30px',
    "&:hover": {      
      backgroundColor: '#c01def',
      color: '#FFF',
    }
  },
}))(Button);

const formatterPeso = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
});

function obtenerFecha(){
    var getfecha = new Date();
    return getfecha.getFullYear()+'-'+('0' + (getfecha.getMonth()+1)).slice(-2)+'-'+('0' + (getfecha.getDate())).slice(-2) + ' '+ ('0' + (getfecha.getHours())).slice(-2) +':'+('0' + (getfecha.getMinutes())).slice(-2)+':'+('0' + (getfecha.getSeconds())).slice(-2);
}

export default function ComplementoPago() {  

  let refValueDateInicial = useRef();
  let refValueDateFinal = useRef();  

  // Variables filtro busqueda fechas
  var fecha = new Date();
  var fechaInicioMes = fecha.getFullYear()+'-'+('0' + (fecha.getMonth()+1)).slice(-2)+'-01';
  var fechaActual = fecha.getFullYear()+'-'+('0' + (fecha.getMonth()+1)).slice(-2)+'-'+('0' + (fecha.getDate())).slice(-2);
  
  const classes = useStyles();   

  // Carga de Datos
  let [state, setState] = useState({
      items: [],
      isLoaded: false
  })

  const [showFechaInit, setFechaInit] = useState(false);
  const [showFechaFin, setFechaFin] = useState(false);

  // Config checkbox
  const newValue = [];
  const [checkedItems, setCheckedItems] = React.useState({});
  const handleChange = (event) => {
      // updating an object instead of a Map
      setCheckedItems({...checkedItems, [event.target.name] : event.target.checked });
      console.log("checkedItems: ", checkedItems);
  }

  // Modales
  const [showDetalle, setDetalle] = useState(false);
  const [showDetalleRemision, setDetalleRemision] = useState(false);
  const [showDetallePedido, setDetallePedido] = useState(false);

  
  const [showdblFactura, setdblFactura] = useState();

  // Cierre Modales
  function handleCierreModal() {    
      setDetalle(!showDetalle);        
  };
  function handleCierreModalRemision() {    
      setDetalleRemision(!showDetalleRemision);        
  };
  function handleCierreModalPedido() {    
      setDetallePedido(!showDetallePedido);        
  };


  // Filtrar Factura
  function customSetFiltrarFactura () {       
      setState({ isLoaded: false });
      setFechaInit(refValueDateInicial.current.value);
      setFechaFin(refValueDateFinal.current.value);      
      consultaFactura(refValueDateInicial.current.value,refValueDateFinal.current.value)
  }

  // Consulta Factura
  async function consultaFactura(fechaInicial, fechaFinal) {  
      var fechaFacturaInicial = "";
      var fechaFacturaFinal = "";
      var today = new Date();    
      if (fechaInicial === undefined || fechaInicial === ""){
          fechaFacturaInicial = today.getFullYear()+'-'+('0' + (today.getMonth()+1)).slice(-2)+'-01 00:00:00';      
      } else {
          fechaFacturaInicial = fechaInicial;
      }
      if (fechaFinal === undefined || fechaFinal === ""){
          fechaFacturaFinal = fechaActual +" 23:59:59";
      } else {
          fechaFacturaFinal = fechaFinal+" 23:59:59";
      }
  
      let userName = null;

      try{
          userName = await Auth.currentUserInfo();  
          userName  = userName.attributes.email;
      }catch(e){
          console.log('Error get user')
      }

      let fechaAlta = obtenerFecha();
          
      var url = 'https://phkw4bym42.execute-api.us-east-2.amazonaws.com/produccion/facturacion/facturas';
      var data = {
          "strEmail": userName,           
          "datFechaFacturaInicio": fechaFacturaInicial,
          "datFechaFacturaFin": fechaFacturaFinal,
          "datFechaAlta": fechaAlta
      };

      console.log(data)
  
      fetch(url, {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers:{
              'Content-Type': 'application/json'
          }
      })
      .then(res => res.json())
      .then(json => {      
          console.log(json[0]);      
          setState({
              isLoaded: true,
              items: json[0],
          });
      });
  }

  useEffect(() => {      
      consultaFactura();        
  },[]);

  function checkboxActivos(){        
      alert(newValue);
  }

  var { isLoaded, items} = state

    if (!isLoaded){
        return(      
            <div className={"row justify-content-center"}>
                <CircularProgress className={classes.progress} color="secondary" size={100}/>      
            </div>
        )
    } else { 

        return (
            <div>
                {/* FILTRO */}
                <Paper className={classes.filtro}>
                    <Typography variant="h5" component="h3">          
                        <form className={classes.container} noValidate>
                            <div >
                                <Button variant="contained" size="large" style={{ backgroundColor: '#e6e5eb' }} onClick={() => customSetFiltrarFactura()}>
                                    <SearchIcon style={{ fontSize: 28}}/>
                                </Button>              
                            </div>
                          
                            <TextField                
                                inputRef={refValueDateInicial}
                                id="fechaFacturaInicio"
                                label="Fecha Factura Inicial"
                                type="date"                
                                defaultValue={(showFechaInit ? showFechaInit: fechaInicioMes)}
                                className={classes.textField}
                                style = {{width: '42%'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                inputRef={refValueDateFinal}
                                id="fechaFacturaFin"
                                label="Fecha Factura Final"
                                type="date"
                                defaultValue={(showFechaFin ? showFechaFin: fechaActual)}
                                className={classes.textField}
                                style = {{width: '42%'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>                              
                    </Typography>
                </Paper>  
                {/* END FILTRO */}  

                <Paper className={classes.paper}>
                    <Table className={classes.table} size="small">
                    
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell align='center' style={{color:'#FFF'}}>&nbsp;</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Factura</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Fecha</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Estatus</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Moneda</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Importe</TableCell>                                
                                <TableCell align='center' style={{color:'#FFF'}}>Descuento</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Subtotal</TableCell>
                                <TableCell align='center' style={{color:'#FFF'}}>Iva</TableCell>                                
                                <TableCell align='center' style={{color:'#FFF'}}>Total</TableCell>         
                            </TableRow>      
                        </TableHead>       
                        <TableBody>
                            {items.map((item, i) => {
                               
                                return (
                                    <Fragment key={'prueba-'+i}>                                         
                                        <TableRow key={'datos-'+i}>
                                            <TableCell align="center"  className={classes.tableDatos}>
                                                <Checkbox
                                                    // name={item.dblFactura}
                                                    checked={checkedItems[item.dblFactura]}                                    
                                                    onChange={(event) => {
                                                        console.log(event.target.checked);
                                                        if (event.target.checked) {
                                                            newValue.push(item.dblFactura);
                                                        } else {
                                                            newValue.splice(newValue.indexOf(item.dblFactura), 1);
                                                        }
                                                        console.log(newValue);
                                                    }}
                                                    value={item.dblFactura}
                                                    inputProps={{
                                                        'aria-label': 'primary checkbox',
                                                    }}
                                                />    
                                                </TableCell>
                                            <TableCell align="center"  className={classes.tableDatos}> 
                                                {item.Factura}                                           
                                            </TableCell>
                                            <TableCell align="center"  className={classes.tableDatos}> 
                                                {
                                                    ('0' + (new Date(item.Fecha).getDate()+1)).slice(-2) + 
                                                    '/' + ('0' + (new Date(item.Fecha).getMonth()+1)).slice(-2) + 
                                                    '/' + new Date(item.Fecha).getFullYear()                                          
                                                }
                                            </TableCell>
                                            <TableCell align="center"  className={classes.tableDatos}> 
                                                {item.Estatus}
                                            </TableCell>
                                            <TableCell align="center"  className={classes.tableDatos}> 
                                                {item.strMoneda}
                                            </TableCell>
                                            <TableCell align="center"  className={classes.tableDatos}> 
                                                {                                            
                                                    formatterPeso.format(item.dblImporte)   
                                                }
                                            </TableCell>
                                            <TableCell align="center"  className={classes.tableDatos}> 
                                                {                                        
                                                    formatterPeso.format(item.dblDescuento)                                           
                                                }
                                            </TableCell>
                                            <TableCell align="center"  className={classes.tableDatos}> 
                                                {                                         
                                                    formatterPeso.format(item.Subtotal)    
                                                }
                                            </TableCell>
                                            <TableCell align="center"  className={classes.tableDatos}> 
                                                {                                                   
                                                    formatterPeso.format(item.Iva)    
                                                }
                                            </TableCell>
                                            <TableCell align="center"  className={classes.tableDatos}> 
                                                {
                                                    formatterPeso.format(item.Total)
                                                }
                                            </TableCell>
                                        </TableRow>                                                                                    
                              
                                        <TableRow key={'opciones-'+i}>
                                            <TableCell colSpan={10} >
                                            <div className="flex-column" style={{display:'grid', justifyContent:'center'}}>
                                                <ButtonGroup size="sm">
                                                <button                                                    
                                                    id="btnDetalle"
                                                    type="button"                
                                                    className={`btn btn-outline-primary btn-elevate kt-login__btn-primary `}    
                                                    style={{borderRadius: '20px',align: 'center', marginRight: '20px'}}
                                                    onClick={() => {
                                                        setdblFactura(item.dblFactura);
                                                        setDetalle(true);                                                    
                                                    }}                                                  
                                                >
                                                    <img
                                                        alt="detalle"
                                                        src={toAbsoluteUrl("/media/iconos/small/detalle.png")}
                                                        // width="40%"                    
                                                    />
                                                    Detalle
                                                </button>
                                                {/* REMISION */}
                                                {
                                                    item.dblRemision1 ?
                                                        <button                                                            
                                                            id="btnDetalleRemision"
                                                            type="button"                
                                                            className={`btn btn-outline-warning btn-elevate kt-login__btn-primary `}    
                                                            style={{borderRadius: '20px',align: 'center', marginRight: '20px'}}
                                                            // onClick={() => setDetalleRemision(true)}
                                                            onClick={() => {
                                                              setdblFactura(item.dblFactura);
                                                              setDetalleRemision(true);                                                    
                                                          }} 
                                                        >
                                                            <img
                                                                alt="Remision"
                                                                src={toAbsoluteUrl("/media/iconos/small/truck.png")}
                                                                // width="40%"                    
                                                            />
                                                            Remisión
                                                        </button>                                       
                                                    : null
                                                }
                                                {/* Pedido */}         
                                                {
                                                    item.dblRemision1 ? 
                                                        <BtnPurple
                                                            style={{marginRight: '20px', borderRadius:'20px'}}
                                                            className={'btn btn-elevate kt-login__btn-primary'}
                                                            id="btnDetallePedido"
                                                            type="button"                                                            
                                                            onClick={() => {
                                                              setdblFactura(item.dblFactura);
                                                              setDetallePedido(true);                                                    
                                                          }} 
                                                        >
                                                            <img
                                                                alt="Remision"
                                                                src={toAbsoluteUrl("/media/iconos/small/order.png")}
                                                                // width="40%"                    
                                                            />
                                                            Pedido
                                                        </BtnPurple>
                                                    :null
                                                }     
                                                {/* PDF */}
                                                {item.strXMLFactura ?
                                                    <FacturaPDF 
                                                        strFactura={item.Factura}   
                                                        dblFactura = {item.NFactura}
                                                        strArchivoFactura = {item.strArchivoFactura}                                                                        
                                                    />
                                                    :null
                                                }
                                                {/* XML */}
                                                {item.strXMLFactura  && item.intEstatusFactura !=4 ?
                                                    <FacturaXML 
                                                        strFactura={item.Factura}                                                   
                                                        dblFactura = {item.NFactura}                                                                               
                                                    />
                                                    :null
                                                }
                                                
                                                </ButtonGroup>
                                                </div>                                                                                                                                                  
                                            </TableCell>
                                        </TableRow>
                                        
                                                                                                                  
                                    </Fragment>
                                );
                            })} 
                            { showDetalle ?  
                                <ModalDetalleFactura 
                                    handleCierreModal={handleCierreModal} 
                                    dblFactura={showdblFactura}                                    
                                    show={showDetalle}                                           
                                    title={'Detalle de Factura'}
                                /> : null 
                            }  
                            { showDetalleRemision ?  
                                <ModalDetalleFacturaRemision 
                                    handleCierreModalRemision={handleCierreModalRemision} 
                                    dblFactura={showdblFactura}    
                                    show={showDetalleRemision}                                           
                                    title={'Remisión'}
                                /> : null 
                            }     
                            { showDetallePedido ?  
                                <ModalDetalleFacturaPedido 
                                    handleCierreModalPedido={handleCierreModalPedido} 
                                    dblFactura={showdblFactura}
                                    show={showDetallePedido}                                           
                                    title={'Pedido'}
                                /> : null 
                            }      
                        </TableBody>
                                                          
                    </Table>
                </Paper>

                <div>                        
                    <button
                        id="btnDescargaMasiva"
                        type="submit"                
                        className={`btn btn-danger btn-elevate kt-login__btn-primary `}
                        style={{borderRadius: '20px',display: 'flex',align: 'center', margin:'10px'}}
                        onClick={() => checkboxActivos()}
                    >
                        <img
                            alt="Remision"
                            src={toAbsoluteUrl("/media/iconos/small/download.png")}
                            // width="40%"  
                            style={{marginRight:'10px'}}                  
                        />
                        Descarga de Documentos
                    </button>                   
                </div>
                {/*                  
                                  
                                            <button
                                                id="btnDetalle"
                                                type="button"                
                                                className={`btn btn-outline-primary btn-elevate kt-login__btn-primary `}    
                                                style={{borderRadius: '20px',display: 'flex',align: 'center'}}
                                                onClick={() => setDetalle(true)}
                                            >
                                                <img
                                                    alt="detalle"
                                                    src={toAbsoluteUrl("/media/iconos/small/detalle.png")}
                                                    width="40%"                    
                                                />
                                                Detalle
                                            </button>
                                            { showDetalle ?  
                                                <ModalDetalleFactura 
                                                    handleCierreModal={handleCierreModal} 
                                                    dblFactura={item.dblFactura}
                                                    show={showDetalle}                                           
                                                    title={'Detalle de Factura'}
                                                /> : null 
                                            }
                                              
                                              {
                                                  item.dblRemision1 ?
                                                      <button
                                                          id="btnDetalleRemision"
                                                          type="button"                
                                                          className={`btn btn-outline-warning btn-elevate kt-login__btn-primary `}    
                                                          style={{borderRadius: '20px',display: 'flex',align: 'center'}}
                                                          onClick={() => setDetalleRemision(true)}
                                                      >
                                                          <img
                                                              alt="Remision"
                                                              src={toAbsoluteUrl("/media/iconos/small/truck.png")}
                                                              width="40%"                    
                                                          />
                                                          Remisión
                                                      </button>                                       
                                                  : null
                                              }
                                              { showDetalleRemision ?  
                                                  <ModalDetalleFacturaRemision 
                                                      handleCierreModalRemision={handleCierreModalRemision} 
                                                      dblFactura={item.dblFactura}
                                                      show={showDetalleRemision}                                           
                                                      title={'Remisión'}
                                                  /> : null 
                                              }
                                              

                                              

                                              {
                                                  item.dblRemision1 ? 
                                                      <BtnPurple
                                                          id="btnDetallePedido"
                                                          type="button"
                                                          onClick={() => setDetallePedido(true)}
                                                      >
                                                          <img
                                                              alt="Remision"
                                                              src={toAbsoluteUrl("/media/iconos/small/order.png")}
                                                              width="40%"                    
                                                          />
                                                          Pedido
                                                      </BtnPurple>
                                                  :null
                                              }                           
                                              
                                              { showDetallePedido ?  
                                                  <ModalDetalleFacturaPedido 
                                                      handleCierreModalPedido={handleCierreModalPedido} 
                                                      dblFactura={item.dblFactura}
                                                      show={showDetallePedido}                                           
                                                      title={'Pedido'}
                                                  /> : null 
                                              }

                                            

                                              
                                              {item.strXMLFactura ?
                                                  <FacturaPDF 
                                                      strFactura={item.Factura}   
                                                      dblFactura = {item.NFactura}                                                                               
                                                  />
                                                  :null
                                              }

                                              
                                              
                                              {item.strXMLFactura  && item.intEstatusFactura !=4 ?
                                                  <FacturaXML 
                                                      strFactura={item.Factura}                                                   
                                                      dblFactura = {item.NFactura}                                                                               
                                                  />
                                                  :null
                                              }
                                              
                  */}
            </div>
        );
    }
}